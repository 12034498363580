import axios from "axios";
import { DCAuthToken } from "./Settings";
const token = DCAuthToken();
const XData = axios.create({
  baseURL: process.env.REACT_APP_USER_API_BASE_URL + "promotion", 
  headers : { Authorization : 'Bearer ' + token }
});

export async function GetPromos(SetDatas)
{
  const result = await XData.get('/');
  SetDatas(result.data);
  
}

export async function CurrentPromos(SetDatas)
{
  const result = await XData.get('/current');
  SetDatas(result.data);
  
}

export async function GetPromo(id, SetDatas)
{
    const result = await XData.get('/'+id);
    SetDatas(result.data);
  
}

export async function SavePromo(newpromo, setShowForm)
{
    
  if(navigator.onLine)
  {
    var status = 0;
    // si l'utilisateur est connecté, on met à jour les données sur le serveur
    const body = {
      Title: newpromo.Title, 
      Content: newpromo.Content, 
      PricePrefix: newpromo.PricePrefix, 
      Price: newpromo.Price, 
      StartDate: newpromo.StartDate, 
      EndDate: newpromo.EndDate, 
      State: newpromo.State,
      Classements: newpromo.Classements,
      ATCs: newpromo.ATCs,
      Specialiste: newpromo.Specialiste,
      Activite: newpromo.Activite,
      Profils: newpromo.Profils,
      RemiseSpecifique: newpromo.RemiseSpecifique,
      image: newpromo.image,
      document: newpromo.document
    };
    var result = [];
    if(newpromo.id > 0)
    {
      result = await XData.put("/"+newpromo.id, body);
      setShowForm(false);
    }
    else
    {
      result = await XData.post("/", body);
      setShowForm(false);
    }
  }
}

export async function DeletePromo(id, afterDelete)
{
  const result = await XData.delete('/'+id);
  afterDelete();
  
}

export function PathImagePromo(id)
{
  return process.env.REACT_APP_USER_API_BASE_URL +'promotion/image/'+id+'?token='+token;
}

export function PathDocumentPromo(id)
{
    return process.env.REACT_APP_USER_API_BASE_URL +'promotion/document/'+id+'?token='+token;
}

export async function OrdreUp(id, afterOrder)
{
  const result = await XData.put("/"+id+"/orderup", {});
  afterOrder(result.data);
}

export async function OrdreDown(id, afterOrder)
{
  const result = await XData.put("/"+id+"/orderdown", {});
  afterOrder(result.data);
}

