import { useState, useEffect, useRef } from 'react';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import DCLoader from './DCLoader';
import { GetAccount, MailReinitPassword } from '../functions/Users';
import { GetTypesEquipements } from '../functions/TypeEquipements';
import { date_to_fr } from '../functions/tools';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquare, faSquareCheck, faChevronLeft, faCheckSquare } from '@fortawesome/free-solid-svg-icons'
import Modal from 'react-bootstrap/Modal';


function Account(props) {

  const [firstInit, SetFirstInit] = useState(false);
  const [stateWindow, setStateWindow] = useState(0);
  const [theRecord, SetTheRecord] = useState([]);
  
  const [userId, setUserId] = useState(0);
  const [Type, setType] = useState(0);
  const [Code, setCode] = useState("");
  const [Email, setEmail] = useState("");
  const [Commercial, setCommercial] = useState("");
  const [Enseigne, setEnseigne] = useState("");
  const [FirstName, setFirstName] = useState("");
  const [Name, setName] = useState("");
  const [Password, setPassword] = useState("");
  const [Company, setCompany] = useState("");
  const [Phone, setPhone] = useState("");
  const [Mobile, setMobile] = useState("");
  const [Address, setAddress] = useState("");
  const [Address2, setAddress2] = useState("");
  const [Zip, setZip] = useState("");
  const [City, setCity] = useState("");
  const [Nb_Apprentis, setNbApprentis] = useState(0);
  const [Activite_garage, setActiviteGarage] = useState(0);
  const [Activite_carrosserie, setActiviteCarrosserie] = useState(0);
  const [Activite_atelier, setActiviteAtelier] = useState(0);
  const [Specialiste_VL, setSpecialisteVL] = useState(0);
  const [Specialiste_PL, setSpecialistePL] = useState(0);
  const [Categorie, setCategorie] = useState("");
  const [Equipements, setEquipements] = useState([]);
  const [Magasin, setMagasin] = useState([]);
  
  const [types_equipements, setTypesEquipements] = useState([]);
  let [loading, setLoading] = useState(false);

  
  if(firstInit==false)
  {
    /*if(props.idVisit > 0)
    {
      setLoading(true);
      SPSGetVisite(props.idVisit, SetTheVisit);
    }*/
    GetTypesEquipements(setTypesEquipements);
    SetFirstInit(true);
  }
  


  useEffect(() => {
    
    if(stateWindow == 0)
    {
      searchDatas();
    }
    setLoading(false);
  }, [stateWindow]);

  useEffect(() => {
    if(theRecord.Email != undefined)
    {
      setLoading(true);
      setUserId(theRecord.id);
      setType(theRecord.Type);
      setCode(theRecord.Code);
      setCommercial(theRecord.Commercial);
      setEnseigne(theRecord.Enseigne);
      setEmail(theRecord.Email);
      setFirstName(theRecord.FirstName);
      setName(theRecord.Name);
      setPassword("");
      setCompany(theRecord.Company);
      setPhone(theRecord.Phone);
      setMobile(theRecord.Mobile);
      setAddress(theRecord.Address);
      setAddress2(theRecord.Address2);
      setZip(theRecord.Zip);
      setCity(theRecord.City);
      setActiviteGarage(theRecord.Activite_garage);
      setActiviteCarrosserie(theRecord.Activite_carrosserie);
      setActiviteAtelier(theRecord.Activite_atelier);
      setSpecialisteVL(theRecord.Specialiste_VL);
      setSpecialistePL(theRecord.Specialiste_PL);
      setNbApprentis(theRecord.Nb_Apprentis);
      setCategorie(theRecord.Categorie);
      setEquipements(theRecord.Equipements);
      setMagasin(theRecord.Magasin.Nom)
      setStateWindow(1);
      setLoading(false);
    }
  }, [theRecord]);

  const handleRetour = () => {
    props.screenHandler('');
  }
  
  function searchDatas()
  {
    GetAccount(SetTheRecord);
  }
  const labelTypeEquipement = (id) => {
    var retour = '';
    const p = types_equipements.filter((r) => r.id == id);
    if(p.length > 0)
      retour = p[0]['Libelle'];

    return retour;
  }
  

  
  const handleMailReinit = () => {
    MailReinitPassword(userId, afterMailRetinit)
  };
  const afterMailRetinit = (ok) => {
    if(ok > 0)
      alert("Email de réinitialisation de mot de passe envoyé");
    else
      alert("Impossible d'envoyer l'email !");
  };
  

  
  const handleSubmit = (event) => {
    event.preventDefault();
    // do nothing
  };
  
  


    return (
        <div className="container border border-primary">
        <div className="row headScreen px-0">
            <div className="col-3 text-start py-2"><button className="btn btn-secondary" type="button" onClick={handleRetour}><FontAwesomeIcon icon={faChevronLeft} /> Retour</button></div>
            <div className="col-6 text-center"><h1 className="h4 py-3">{Company}</h1></div>
            <div className="col-3 text-end py-2"></div>
        </div>
        <DCLoader load={loading} />
        
            <div className="p-3">
            {Type != 1 && Type != 2? 
            <div>
            <h3>Garage</h3>
            <div className="form-group mb-3">
                
                <div className="row">
                <div className="col-md-6">
                    <label  >Magasin</label>
                    <div className="readonly-field">{Magasin}</div>
                </div>
                
                </div>
            </div>
            <div className="form-group mb-3">
                
                <div className="row">
                <div className="col-md-6">
                    <label  >Code</label>
                    <div className="readonly-field">{Code}</div>
                </div>
                <div className="col-md-6">
                    <label  >Commercial</label>
                    <div className="readonly-field">{Commercial}</div>
                </div>
                </div>
            </div>
            
            <div className="form-group mb-3">
            <div className="row">
                <div className="col-md-6">
                  <label >Profil</label>
                
                  <div className="readonly-field">{Categorie}</div>
                </div>
                <div className="col-md-6">
                  <label className="d-block">Spécialiste</label>
                  <div className="d-inline me-4">
                    <label className="form-check-label" for="activiteGarage">VL : {Specialiste_VL>0?<FontAwesomeIcon icon={faSquareCheck} />:<FontAwesomeIcon icon={faSquare} />}</label>
                  </div>
                  <div className="d-inline me-4">
                    <label className="form-check-label" for="activiteCarrosserie">PL : {Specialiste_PL>0?<FontAwesomeIcon icon={faSquareCheck} />:<FontAwesomeIcon icon={faSquare} />} </label>
                  </div>
                </div>
                </div>
            </div>
            <div className="form-group mb-3">
                
                <div className="row">
                  <div className="col-md-6">
                      <label  >Enseigne</label>
                      <div className="readonly-field">{Enseigne}</div> 
                  </div>
                  <div className="col-md-6">
                    <label className="d-block">Activité</label>
                    <div className="d-inline me-4">
                      <label className="form-check-label" for="activiteGarage">Garage : {Activite_garage>0?<FontAwesomeIcon icon={faSquareCheck} />:<FontAwesomeIcon icon={faSquare} />}</label>
                    </div>
                    <div className="d-inline me-4">
                      <label className="form-check-label" for="activiteCarrosserie">Carrosserie : {Activite_carrosserie>0?<FontAwesomeIcon icon={faSquareCheck} />:<FontAwesomeIcon icon={faSquare} />} </label>
                    </div>
                    <div className="d-inline">
                      <label className="form-check-label" for="activiteCarrosserie">Atelier intégré : {Activite_atelier>0?<FontAwesomeIcon icon={faSquareCheck} />:<FontAwesomeIcon icon={faSquare} />} </label>
                    </div>
                  </div>
                </div>
            </div>
            <div className="form-group row mb-3">
                <div className="col-md-6">
                <label id="label-chantier" >Adresse</label>
                <div className="readonly-field">{Address}</div><br />
                <div className="readonly-field">{Address2}</div>
                </div>
                <div className="col-md-6">
                <label id="label-chantier" >Nombre d'apprentis</label>
                <div className="readonly-field">{Nb_Apprentis}</div>
                </div>
            </div>
            <div className="form-group row mb-3">
                <div className="col-md-4">
                <label id="label-chantier" >Code postal</label>
                <div className="readonly-field">{Zip}</div>
                </div>
                <div className="col-md-8">
                <label id="label-chantier" >Ville</label>
                <div className="readonly-field">{City}</div>
                </div>
            </div>
            </div>
            :""}
            <h3>Contact</h3>
            <div className="form-group row mb-3">
                <div className="col-md-6">
                <label id="label-chantier" >Prénom</label>
                <div className="readonly-field">{FirstName}</div>
                </div>
                <div className="col-md-6">
                <label id="label-chantier" >Nom</label>
                <div className="readonly-field">{Name}</div>
                </div>
            </div>
            
            <div className="form-group row mb-3">
                <div className="col-md-6">
                  <label id="label-chantier" >Email</label>
                  <div className="readonly-field">{Email}</div>
                </div>
                <div className="col-md-3">
                  <label id="label-chantier" >Téléphone</label>
                  <div className="readonly-field">{Phone}</div>
                </div>
                <div className="col-md-3">
                  <label id="label-chantier" >Mobile</label>
                  <div className="readonly-field">{Mobile}</div>
                </div>
            </div>
            <div className="form-group row mb-3">
                <div className="col-md-6">
                <label className="pe-4" >Mot de passe : </label>
                <button type="button" className="btn btn-secondary" onClick={handleMailReinit}>Envoyer email de réinitialisation de mot de passe</button>
                </div>
                <div className="col-md-6">
                
                </div>
            </div>
            {Type != 1 && Type != 2? 
            <div>
            <div className="row">
                <h3 className="col-8">Equipements</h3>
            </div>
            
            <div className="row">
                <div className="col-md-6">
                <table className="table">
                    <thead>
                    <tr>
                        <th>Equipement</th>
                        <th>Qté</th>
                        <th>Marque</th>
                        <th>Date de fin</th>
                    </tr>
                    </thead>
                    <tbody>
                    {Equipements.map((d) =>(
                    <tr key={d.id} >
                        <td>{labelTypeEquipement(d.IdTypeEquipement)}</td>
                        <td>{d.Quantite}</td>
                        <td>{d.Marque}</td>
                        <td>{date_to_fr(d.Date_Fin)}</td>
                    </tr>
                    ))}
                    </tbody>
                </table>
                </div>
                <div className="col-md-6">
                <table className="table">
                    <thead>
                    <tr>
                        <th>Equipement</th>
                        <th>Qté</th>
                        <th>Marque</th>
                        <th>Date de fin</th>
                    </tr>
                    </thead>
                    <tbody>
                    
                    </tbody>
                </table>
                </div>
            </div>
            </div>
            :""} 
            
            </div>
            
        
        
        </div>
    );
  
}

export default Account;
