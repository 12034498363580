import { useState, useEffect, useRef } from 'react';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import DCLoader from './DCLoader';

import {DCGetSetting, DCSaveSetting, PathDocSetting} from '../functions/Settings';
import { GetCatalogues, GetCatalogue, SaveCatalogue, DeleteCatalogue, PathImageCatalogue, PathDocumentCatalogue } from '../functions/Catalogues';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashCan, faChevronLeft, faImage, faFilePdf } from '@fortawesome/free-solid-svg-icons'
import { Editor } from '@tinymce/tinymce-react';
import { initConfig } from '../functions/configEditor';

function ADCatalogues(props) {

  const [firstInit, SetFirstInit] = useState(false);
  const [stateWindow, setStateWindow] = useState(0);
  const [liste, SetListe] = useState([]);
  const [theRecord, SetTheRecord] = useState([]);
  
  const [CatId, setCatId] = useState(0);
  const [Title, setTitle] = useState("");
  const [initialContent, setInitialContent] = useState("");
  const [Content, setContent] = useState("");
  const [State, setState] = useState("");
  const [image, setImage] = useState("");
  const [imagePrevisu, setImagePrevisu] = useState("");
  const [fond, setFond] = useState("");
  const [fondPrevisu, setFondPrevisu] = useState("");
  const [document, setDocument] = useState("");
  const [documentName, setDocumentName] = useState("");

  let [loading, setLoading] = useState(false);

  const editorRef = useRef(null);
  const [editEditor, setEditEditor] = useState("");
  const handleChangeEditor = (value, editor) => {
    setContent(value);
  };
  
  if(firstInit==false)
  {
    
    SetFirstInit(true);
  }
  


  useEffect(() => {
    
    if(stateWindow == 0)
    {
      searchDatas();
    }
    setLoading(false);
  }, [stateWindow]);

  useEffect(() => {
    if(theRecord.id != undefined)
    {
      setLoading(true);
      setCatId(theRecord.id);
      setTitle(theRecord.Title);
      setContent(theRecord.Content);
      setInitialContent(theRecord.Content);
      setState(theRecord.State);
      setImagePrevisu(theRecord.image);
      setFondPrevisu(theRecord.fond);
      setDocumentName(theRecord.document);
      setDocument("");
      
      setStateWindow(1);
      setLoading(false);
    }
  }, [theRecord]);

  
  function searchDatas()
  {
    GetCatalogues(SetListe);
  }

  const handleAdd = (event) => {
    setCatId(0);
    setTitle("");
    setContent("");
    setState(0);
    setImagePrevisu("");
    setFondPrevisu("");
    setDocumentName("");
    setDocument("");
    setStateWindow(1);
  };
  const handleCancelAdd= (event) => {
    /*if(props.idVisit > 0)
      props.screenHandling(0);
    else*/
    setStateWindow(0);
  };
  const handleClick = (id) => {
    setLoading(true);
    GetCatalogue(id, SetTheRecord);
  };
  const handleDelete = (id) => {
    confirmAlert({
      title: 'Suppression',
      message: 'Souhaitez-vous supprimer ce catalogue ?',
      buttons: [
        {
          label: 'Oui',
          onClick: () => {
            DeleteCatalogue(id, afterDelete);
          }
        },
        {
          label: 'Non',
          onClick: () => { 

          }
        }
      ]
    });
  };
  
  const afterDelete = () => {
    searchDatas();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    saveData(setStateWindow);
    
  };

  const handleGalerie = (e) => {
    readImage(e.target.files[0]);
  };

  const handleFond = (e) => {
    const file = e.target.files[0];
    // Check if the file is an image.
    if (file.type && file.type.indexOf('image') === -1) {
      console.log('File is not an image.', file.type, file);
      return;
    }
  
    const reader = new FileReader();
    reader.addEventListener('load', (event) => {
       
        setFond(event.target.result);
    });
    reader.readAsDataURL(file);
  };

  const handleDocument = (e) => {
    const file = e.target.files[0];
    // Check if the file is an image.
    if (file.type && file.type.indexOf('application/pdf') === -1) {
      console.log('File is not a PDF.', file.type, file);
      return;
    }
  
    const reader = new FileReader();
    reader.addEventListener('load', (event) => {
       
        setDocument(event.target.result);
    });
    reader.readAsDataURL(file);
  };

  
  const saveData = (handleEnd) => {
    setLoading(true);
    const body = {
      id:CatId,
      Title: Title,
      Content: Content,
      State: State,
      image: image,
      fond: fond,
      document: document
    };
    
    SaveCatalogue(body, handleEnd);
  };


  const handleBack = (event) => {
    props.screenHandler('');
  };

  function readImage(file) {
      // Check if the file is an image.
      if (file.type && file.type.indexOf('image') === -1) {
        console.log('File is not an image.', file.type, file);
        return;
      }
    
      const reader = new FileReader();
      reader.addEventListener('load', (event) => {
         
          setImage(event.target.result);
      });
      reader.readAsDataURL(file);
  }

    switch(stateWindow)
    {
      case 1:

        return (
          <div className="container border border-primary">
            <div className="row headScreen px-0">
              <div className="col-3 text-start py-2"><button className="btn btn-secondary" type="button" onClick={handleCancelAdd}><FontAwesomeIcon icon={faChevronLeft} /> Annuler</button></div>
              <div className="col-6 text-center"><h1 className="h4 py-3">Catalogue</h1></div>
              <div className="col-3 text-end py-2"><button className="btn btn-primary" type="button" onClick={handleSubmit}>Enregistrer</button></div>
            </div>
            <DCLoader load={loading} />
            <form onSubmit={handleSubmit}>
              <div className="p-3">
                <input type="hidden" name="id" value={CatId} />
                
                <div className="form-group mb-3">
                  <label id="label-chantier" >Titre</label>
                  <div className="row">
                    <div className="col-12">
                      <input type="text" name="Title" value={Title} className="form-control" onChange={e => { setTitle(e.target.value); }}/>
                    </div>
                  </div>
                </div>
                <div className="form-group mb-3">
                  <label id="label-chantier" >Etat</label>
                  <div className="row">
                    <div className="col-md-4">
                      <select name="State" className="form-select"  value={State} onChange={e => { setState(e.target.value); }}>
                        <option value="0">Brouillon</option>
                        <option value="1">Publié</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="form-group mb-3">
                  <label id="label-chantier" >Contenu</label>
                  <div className="row">
                    <div className="col-12">
                        <Editor
                            onInit={(evt, editor) => editorRef.current = editor}
                            initialValue={initialContent}
                            init={initConfig}
                            tinymceScriptSrc="/scripts/tinymce/tinymce.min.js"
                            onEditorChange={handleChangeEditor}
                        />
                    </div>
                  </div>
                </div>
                
                <div className="form-group mb-3">
                  <label>Document</label>
                  <button id="btnDocument" type="button" className="btn btn-light color-primary btn-file " >
                      <label><FontAwesomeIcon icon={faFilePdf} /><input type="file" onChange={handleDocument} required/> Ajouter le document</label>
                  </button>
                  <div className="previsu_image">
                  {document && <div className="text-success">Document prêt à être envoyé</div>}
                  {documentName  && <div><a href={PathDocumentCatalogue(CatId)}>Voir le document</a></div>}
                  
                  </div>
                </div>
                <div className="form-group mb-3">
                  <label>Couverture</label>
                  <button id="btnGalerie" type="button" className="btn btn-light color-primary btn-file " >
                      <label><FontAwesomeIcon icon={faImage} /><input type="file" onChange={handleGalerie} required/> Ajouter une photo</label>
                  </button>
                  <div className="previsu_image">
                  {image != "" && <div className="col-12"><img src={image} className="mw-100" /></div>}
                  {image == "" && imagePrevisu !="" && <div className="col-12"><img src={PathImageCatalogue(CatId)} className="mw-100" /></div>}
                  </div>
                </div>

                <div className="form-group mb-3">
                  <label>Fond</label>
                  <button id="btnFond" type="button" className="btn btn-light color-primary btn-file " >
                      <label><FontAwesomeIcon icon={faImage} /><input type="file" onChange={handleFond} required/> Ajouter un fond</label>
                  </button>
                  <div className="previsu_image">
                  {fond != "" && <div className="col-12"><img src={fond} className="mw-100" /></div>}
                  {fond == "" && fondPrevisu !="" && <div className="col-12"><img src={PathImageCatalogue('bg'+CatId)} className="mw-100" /></div>}
                  </div>
                </div>
                
              </div>
              
            </form>
                      
              
            
          </div>
        );
        break;
      default:
       

        var liste_vide = "";
        if(liste.length == 0)
        {
          liste_vide = <div className="lineEmptyTab">Aucun catalogue</div>
        }
        return (
          <div className="container border border-primary" >
            <div className="row headScreen">
              <div className="col-3 text-start py-2"><button className="btn btn-secondary" onClick={handleBack}><FontAwesomeIcon icon={faChevronLeft} /> Retour</button></div>
              <div className="col-6 text-center"><h1 className="h4 py-3">Catalogues</h1></div>
            </div>
            <DCLoader load={loading} />
            
            <fieldset>
              <legend>Catalogues</legend>
            
              <table className="table">
                <tbody>
                  <tr>
                    <td className="">
                      <ul className="container">
                        {liste.length > 0 && liste.map((d) =>(
                        <li className="row mb-2 alternate-color py-2" key={d.id}  >
                            <div className="col-md-4" onClick={() => handleClick(d.id)}>{d.Title}</div>
                            <div className="col-md-3" onClick={() => handleClick(d.id)}>{d.State==0?"Brouillon":"Publié"}</div>
                            <div className="col-1 text-end">
                              <button type="button" className="btn btn-danger btn-sm" onClick={() => handleDelete(d.id)}><FontAwesomeIcon icon={faTrashCan} /></button>
                            </div>
                        </li>
                        ))}
                      </ul>
                      {liste_vide}
                      <div className="text-center"><button type="button" className="btn btn-primary" onClick={handleAdd}>Créer un catalogue</button></div>
                      
                    </td>
                  </tr>
                </tbody>
              </table>
            </fieldset>
          </div>
        );
        break;
    }
  
}

export default ADCatalogues;
