import { useState, useEffect, useRef } from 'react';
import { confirmAlert } from 'react-confirm-alert'; // Import

import Dropdown from 'react-bootstrap/Dropdown';
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import DCLoader from './DCLoader';
import { GetCommerciaux, GetUser, SaveUser, DeleteUser } from '../functions/Users';
import { GetMagasins } from '../functions/Magasins';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashCan, faChevronLeft } from '@fortawesome/free-solid-svg-icons'



function ADCommerciaux(props) {

  const [firstInit, SetFirstInit] = useState(false);
  const [stateWindow, setStateWindow] = useState(0);
  const [liste, SetListe] = useState([]);
  const [theRecord, SetTheRecord] = useState([]);
  
  const [userId, setUserId] = useState(0);
  const [Email, setEmail] = useState("");
  const [FirstName, setFirstName] = useState("");
  const [Name, setName] = useState("");
  const [Type, setType] = useState(0);
  const [Password, setPassword] = useState("");
  const [userMagasins, setUserMagasins] = useState("");
  
  const [magasins, setMagasins] = useState([]);
  
  let [loading, setLoading] = useState(false);

  
  if(firstInit==false)
  {
    /*if(props.idVisit > 0)
    {
      setLoading(true);
      SPSGetVisite(props.idVisit, SetTheVisit);
    }*/
    GetMagasins(setMagasins);
    SetFirstInit(true);
  }
  


  useEffect(() => {
    
    if(stateWindow == 0)
    {
      searchDatas();
    }
    setLoading(false);
  }, [stateWindow]);

  useEffect(() => {
    if(theRecord.id != undefined)
    {
      setLoading(true);
      setUserId(theRecord.id);
      setEmail(theRecord.Email);
      setFirstName(theRecord.FirstName);
      setName(theRecord.Name);
      setType(theRecord.Type);
      setPassword("");
      var mags = [];
      if(theRecord.Magasins)
      {
        for(var i = 0; i < theRecord.Magasins.length; i++ )
        {
          mags.push(theRecord.Magasins[i].IdMagasin);
        }
      }
      setUserMagasins(mags);
      
      setStateWindow(1);
      setLoading(false);
    }
  }, [theRecord]);

  
  function searchDatas()
  {
    GetCommerciaux(SetListe);
  }

  const handleAdd = (event) => {
    setUserId(0);
    setEmail("");
    setFirstName("");
    setName("");
    setType(0);
    setPassword("");
    setUserMagasins([]);
    setStateWindow(1);
  };
  const handleCancelAdd= (event) => {
    /*if(props.idVisit > 0)
      props.screenHandling(0);
    else*/
    setStateWindow(0);
  };
  const handleClick = (id) => {
    setLoading(true);
    GetUser(id, SetTheRecord);
  };
  const handleDelete = (id) => {
    confirmAlert({
      title: 'Suppression',
      message: 'Souhaitez-vous supprimer ce commercial ?',
      buttons: [
        {
          label: 'Oui',
          onClick: () => {
            DeleteUser(id, afterDelete);
          }
        },
        {
          label: 'Non',
          onClick: () => { 

          }
        }
      ]
    });
  };
  
  const afterDelete = () => {
    searchDatas();
  };
  const handleChangeMagasin = (id, checked) => {
    if(checked)
    {
      // il est coché, s'il n'existe pas dans la liste des magasins, on l'ajoute
      if(!userMagasins.includes(id))
      {
        setUserMagasins([...userMagasins, id]);
      }
    }
    else
    {
      if(userMagasins.includes(id))
      {
        setUserMagasins(userMagasins.filter((r) => r != id));
      }
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    saveData(setStateWindow);
    
  };
  
  const saveData = (handleEnd) => {
    setLoading(true);
    const body = {
      id:userId,
      Type: Type,
      Email: Email,
      FirstName: FirstName,
      Name: Name,
      Password: Password,
      Magasins: userMagasins
    };
    
    SaveUser(body, handleEnd);
  };


  const handleBack = (event) => {
    props.screenHandler('');
  };

  function libelleType(type) 
  {
    var lib = '';
    switch(type)
    {
      case '1':
        lib = 'Administrateur';
        break;
      case '2':
        lib = 'Commercial';
        break;
      case '3':
        lib = 'Client';
        break;
      case '4':
        lib = 'Responsable';
    }

    return lib;
  }

    switch(stateWindow)
    {
      case 1:

        return (
          <div className="container border border-primary">
            <div className="row headScreen px-0">
              <div className="col-3 text-start py-2"><button className="btn btn-secondary" type="button" onClick={handleCancelAdd}><FontAwesomeIcon icon={faChevronLeft} /> Annuler</button></div>
              <div className="col-6 text-center"><h1 className="h4 py-3">Profils</h1></div>
              <div className="col-3 text-end py-2"><button className="btn btn-primary" type="button" onClick={handleSubmit}>Enregistrer</button></div>
            </div>
            <DCLoader load={loading} />
            <form onSubmit={handleSubmit}>
              <div className="p-3">
                <input type="hidden" name="id" value={userId} />
                
                <div className="form-group mb-3">
                  <label id="label-chantier" >Prénom</label>
                  <div className="row">
                    <div className="col-md-4">
                      <input type="text" name="FirstName" value={FirstName} className="form-control" onChange={e => { setFirstName(e.target.value); }}/>
                    </div>
                  </div>
                </div>
                <div className="form-group mb-3">
                  <label id="label-chantier" >Nom</label>
                  <div className="row">
                    <div className="col-md-4">
                      <input type="text" name="Name" value={Name} className="form-control" onChange={e => { setName(e.target.value); }}/>
                    </div>
                  </div>
                </div>
                <div className="form-group mb-3">
                  <label id="label-chantier" >Type</label>
                  <div className="row">
                    <div className="col-md-4">
                      <select name="Type" className="form-select" value={Type} onChange={e => { setType(e.target.value); }}>
                        <option value="0">Choisir</option>
                        <option value="1">Administrateur</option>
                        <option value="2">Commercial</option>
                        <option value="4">Responsable</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="form-group mb-3">
                  <label id="label-chantier" >Email</label>
                  <div className="row">
                    <div className="col-md-4">
                      <input type="text" name="Email" value={Email} className="form-control" onChange={e => { setEmail(e.target.value); }}/>
                    </div>
                  </div>
                </div>
                <div className="form-group mb-3">
                  <label id="label-chantier" >Mot de passe</label>
                  <div className="row">
                    <div className="col-md-4">
                      <input type="password" name="Password" value={Password} className="form-control" onChange={e => { setPassword(e.target.value); }}/>
                    </div>
                  </div>
                </div>
                {Type==4?
                <div className="col-md-12">
                    <label>Magasins</label>
                    <div>
                      {magasins.map((d) => (
                        <div className="form-check form-check-inline" key={d.id}>
                          <input className="form-check-input" type="checkbox" id={"magasin"+d.id} value={d.id}  checked={userMagasins.includes(d.id)?'checked':''} onChange={e => {handleChangeMagasin(d.id, e.target.checked)}}/>
                          <label className="form-check-label" htmlFor={"magasin"+d.id}>{d.Nom}</label>
                        </div>
                      ))}
                    </div>
                </div>:""}
              </div>
              
            </form>
                      
              
            
          </div>
        );
        break;
      default:
        // chargement des préférences (image du bandeau)

        
        var liste_vide = "";
        if(liste.length == 0)
        {
          liste_vide = <div className="lineEmptyTab">Aucun utilisateur</div>
        }
        return (
          <div className="container border border-primary" >
            <div className="row headScreen">
              <div className="col-3 text-start py-2"><button className="btn btn-secondary" onClick={handleBack}><FontAwesomeIcon icon={faChevronLeft} /> Retour</button></div>
              <div className="col-6 text-center"><h1 className="h4 py-3">Profils</h1></div>
            </div>
            <DCLoader load={loading} />
            <table className="table">
              <tbody>
                <tr>
                  <td className="">
                    <ul className="container">
                      {liste.map((d) =>(
                      <li className="row mb-2 alternate-color py-2" key={d.id}  >
                          <div className="col-8" onClick={() => handleClick(d.id)}>{d.FirstName} {d.Name}</div>
                          <div className="col-3" onClick={() => handleClick(d.id)}>{libelleType(d.Type)}</div>
                          <div className="col-1 text-end">
                            <button type="button" className="btn btn-danger btn-sm" onClick={() => handleDelete(d.id)}><FontAwesomeIcon icon={faTrashCan} /></button>
                          </div>
                      </li>
                      ))}
                    </ul>
                    {liste_vide}
                    <div className="text-center"><button type="button" className="btn btn-primary" onClick={handleAdd}>Créer un utilisateur</button></div>
                    
                  </td>
                </tr>
              </tbody>
            </table>
            
          </div>
        );
        break;
    }
  
}

export default ADCommerciaux;
