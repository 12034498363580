
function BottomToolbar(props) {
    return (
        <div id="bttoolbar">
            <div className="container-xxl">
                <div className="row">
                    <div className="col-md-3 ">
                        <div className="bg-light py-3 text-center h-100" onClick={() => {props.screenHandler('Pros')}}>
                            <img src="/img/ico_pros.png" />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="bg-light py-3 text-center h-100" onClick={() => {props.screenHandler('Pieces')}}>
                            <img src="/img/ico_pieces.png" />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="bg-light py-3 text-center h-100" onClick={() => {props.screenHandler('Livraisons')}}>
                        <img src="/img/ico_livraison.png" />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="bg-light py-3 text-center h-100" onClick={() => {props.screenHandler('Callcenter')}}>
                        <img src="/img/ico_call_center.png" />
                        </div>   
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BottomToolbar