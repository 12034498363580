import logo from '../logoextranet.png';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Logout, UserDisplayName, CyberAccess } from '../functions/Users';
import { UnreadMails } from '../functions/Mails';
import { useState, useEffect } from 'react';
import iconeMail from '../images/picto_mail.png';
import logocyber from '../images/logo-cyber.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleUser } from '@fortawesome/free-solid-svg-icons'

const MainNavbar = (props) => { 
    const [firstInit, setFirstInit] = useState(0);
    const [libClient, setLibClient] = useState("");
    const [libMenuClient, setLibMenuClient] = useState("");
    const [typeClient, setTypeClient] = useState(3);
    const [cyberActive, setCyberActive] = useState(0);
    const [expanded, setExpended] = useState(false);

    const [logincyber, setLoginCyber] = useState('');
    const [passcyber, setPassCyber] = useState('');

    const [notifMail, setNotifMail] = useState('');


    const handleAdCommerciaux =  () => {
        setExpended(false);
        props.screenHandler("ADcommerciaux");
    }
    const handleAdClients =  () => {
        setExpended(false);
        props.screenHandler("ADclients");
    }
    const handleAdPromo =  () => {
        setExpended(false);
        props.screenHandler("ADpromo");
    }
    const handleAdCatalogues =  () => {
        setExpended(false);
        props.screenHandler("ADcatalogues");
    }
    const handleAdServices =  () => {
        setExpended(false);
        props.screenHandler("ADservices");
    }
    const handleAdActualites =  () => {
        setExpended(false);
        props.screenHandler("ADActualites");
    }
    const handleHome = () => {
        setExpended(false);
        props.screenHandler("Home");
    }
    const handlePromo = () => {
        setExpended(false);
        props.screenHandler("Promotions");
    }
    const handleActu = () => {
        setExpended(false);
        props.screenHandler("Actualites");
    }

    const handleCatalogues = () => {
        setExpended(false);
        props.screenHandler("Catalogues");
    }
    const handleServices = () => {
        setExpended(false);
        props.screenHandler("Services");
    }
    const handleCyber = () => {
        setExpended(false);
        //CyberAccess(LoginCyber)
        window.open("http://cyber.partsmen.fr/");
    }
    const handleMailbox = () => {
        setExpended(false);
        props.screenHandler("Mailbox");
    }
    const handleAccount = () => {
        setExpended(false);
        props.screenHandler("Account");
    }
    const handleLogout = () => {
        setExpended(false);
        Logout(afterLogout);
    }

    const afterLogout = () => {
        window.location.reload();
    }
    
    const LoginCyber = (user, pass) => {
        setLoginCyber(user);
        setPassCyber(pass);

    }

    useEffect(() => {
        if(logincyber != "")
        {
            //document.getElementById('formcyber').submit();
        }
    }, [logincyber]);

    useEffect(() => {
    
        setLibMenuClient(libClient+<FontAwesomeIcon icon={faCircleUser} />);
      }, [libClient]);

    if(firstInit == false)
    {
        UserDisplayName(setLibClient, setTypeClient, setCyberActive);
        UnreadMails(setNotifMail);
        setInterval(refreshmails, 10000);
        setFirstInit(true);
    }

    function refreshmails()
    {
        UnreadMails(setNotifMail);
    }

  return (
    <header className="App-header" collapseOnSelect="true">
        <Navbar expanded={expanded} bg="light" expand="lg">
        <Container fluid>
            <Navbar.Brand onClick={handleHome}><img src={logo} className="d-inline-block align-top logo-header" alt="Partsmen Groupe" /></Navbar.Brand>
            <Navbar.Toggle onClick={() => setExpended(expanded ? false : "expanded")} aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mx-auto with-separator">
                    {typeClient != 3?
                    <NavDropdown title={<span>Administration</span>} id="basic-nav-dropdown">
                        {typeClient == 1?<NavDropdown.Item onClick={handleAdCommerciaux}>Profils</NavDropdown.Item>:""}
                        <NavDropdown.Item onClick={handleAdClients}>Clients</NavDropdown.Item>
                        {typeClient == 1?<NavDropdown.Divider />:""}
                        {typeClient == 1?<NavDropdown.Item onClick={handleAdPromo}>Promotions</NavDropdown.Item>:""}
                        {typeClient == 1?<NavDropdown.Item onClick={handleAdActualites}>Actualités</NavDropdown.Item>:""}
                        {typeClient == 1?<NavDropdown.Item onClick={handleAdCatalogues}>Catalogues</NavDropdown.Item>:""}
                        {typeClient == 1?<NavDropdown.Item onClick={handleAdServices}>Services</NavDropdown.Item>:""}
                    </NavDropdown>
                    :""}
                    <Nav.Link onClick={handleActu}><span>Côté actus</span></Nav.Link>
                    <Nav.Link onClick={handlePromo}><span>Côté promos</span></Nav.Link>
                    <Nav.Link onClick={handleCatalogues}><span>Côté catalogues</span></Nav.Link>
                    <Nav.Link  onClick={handleServices}><span>Côté services</span></Nav.Link>
                    {cyberActive > 0 ?<Nav.Link  onClick={handleCyber}><img src={logocyber} /></Nav.Link>:""}
                </Nav>
                
                <Nav className="">
                    <NavDropdown title={<div className="d-inline position-relative">
                                            {libClient} <FontAwesomeIcon className="iconavbar" icon={faCircleUser} />
                                            {notifMail>0?
                                            <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                                                {notifMail}
                                            </span>:""}
                                        </div>} id="user-nav-dropdown">
                        <NavDropdown.Item onClick={handleAccount}>Mon compte</NavDropdown.Item>
                        <NavDropdown.Item onClick={handleMailbox}>
                            <div className="ligne-menu position-relative">
                                Messagerie
                                {notifMail>0?
                                <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                                    {notifMail}
                                </span>:""}
                            </div>
                            
                        </NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item onClick={handleLogout}>Déconnexion</NavDropdown.Item>
                        
                    </NavDropdown>

                    
                </Nav>
            </Navbar.Collapse>
        </Container>
        </Navbar>
        {logincyber!='' && passcyber!=''?
        <div className="d-block">
            <form name="cyberform" id="formcyber" action="http://cyber.partsmen.fr/login.aspx" method="post">
                <input type="text" name="ctl00$ContentPlaceHolder1$CtrlLogin1$UserName" value={logincyber} />
                <input type="text" name="ctl00$ContentPlaceHolder1$CtrlLogin1$Password" value={passcyber} />
                <input type="submit" name="submit" value="ok" />
            </form>
        </div>
        :""}
    </header>
  );
}

export default MainNavbar;

