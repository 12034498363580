import axios from "axios";
import { DCAuthToken } from "./Settings";
const token = DCAuthToken();
const XData = axios.create({
  baseURL: process.env.REACT_APP_USER_API_BASE_URL + "actualite", 
  headers : { Authorization : 'Bearer ' + token }
});

export async function GetActus(SetDatas)
{
  const result = await XData.get('/');
  SetDatas(result.data);
  
}

export async function CurrentActus(SetDatas)
{
  const result = await XData.get('/current');
  SetDatas(result.data);
  
}

export async function GetActu(id, SetDatas)
{
    const result = await XData.get('/'+id);
    SetDatas(result.data);
  
}

export async function SaveActu(newactu, setShowForm)
{
    
  if(navigator.onLine)
  {
    var status = 0;
    // si l'utilisateur est connecté, on met à jour les données sur le serveur
    const body = {
      Titre: newactu.Titre, 
      Descriptif: newactu.Descriptif, 
      StartDate: newactu.StartDate, 
      EndDate: newactu.EndDate, 
      image: newactu.image,
      document: newactu.document
    };
    var result = [];
    if(newactu.id > 0)
    {
      result = await XData.put("/"+newactu.id, body);
      setShowForm(false);
    }
    else
    {
      result = await XData.post("/", body);
      setShowForm(false);
    }
  }
}

export async function DeleteActu(id, afterDelete)
{
  const result = await XData.delete('/'+id);
  afterDelete();
  
}

export function PathImageActu(id)
{
  return process.env.REACT_APP_USER_API_BASE_URL +'actualite/image/'+id+'?token='+token;
}

export function PathDocumentActu(id)
{
    return process.env.REACT_APP_USER_API_BASE_URL +'actualite/document/'+id+'?token='+token;
}

