import { useState, useEffect, useRef } from 'react';
import { confirmAlert } from 'react-confirm-alert'; // Import
import Dropdown from 'react-bootstrap/Dropdown';
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import DCLoader from './DCLoader';

import {DCGetSetting, DCSaveSetting, PathDocSetting} from '../functions/Settings';
import { GetPromos, GetPromo, SavePromo, DeletePromo, PathImagePromo, PathDocumentPromo, OrdreUp, OrdreDown } from '../functions/Promos';
import { GetClassements } from '../functions/Classements';
import { GetRemises } from '../functions/Remises';
import { GetCommerciaux, GetProfils } from '../functions/Users';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashCan, faChevronLeft, faImage, faFilePdf, faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { Editor } from '@tinymce/tinymce-react';
import { initConfig } from '../functions/configEditor';

function ADPromos(props) {

  const [firstInit, SetFirstInit] = useState(false);
  
  const [stateWindow, setStateWindow] = useState(0);
  const [liste, SetListe] = useState([]);
  const [theRecord, SetTheRecord] = useState([]);
  
  const [promoId, setPromoId] = useState(0);
  const [Title, setTitle] = useState("");
  const [initialContent, setInitialContent] = useState("");
  const [Content, setContent] = useState("");
  const [PricePrefix, setPricePrefix] = useState("");
  const [Price, setPrice] = useState(0.0);
  const [StartDate, setStartDate] = useState("");
  const [EndDate, setEndDate] = useState("");
  const [State, setState] = useState("");
  const [image, setImage] = useState("");
  const [imagePrevisu, setImagePrevisu] = useState("");
  const [Classements, setClassements] = useState([]);
  const [ATCs, setATCs] = useState([]);
  const [Specialiste, setSpecialiste] = useState([]);
  const [Activite, setActivite] = useState([]);
  const [Profils, setProfil] = useState([]);
  const [RemiseSpecifique, setRemiseSpecifique] = useState(0);
  const [document, setDocument] = useState("");
  const [documentName, setDocumentName] = useState("");
  
 
  const [listeClassements, setListeClassements] = useState([]);

  const [listeRemises, setListeRemises] = useState([]);

  const [commerciaux, setCommerciaux] = useState([]);
  const [profils, setProfils] = useState([]);

  const [BannierePromo, SetBannierePromo] = useState({});
  const [BannierePrevisu, SetBannierePrevisu] = useState({});
  const [BanniereHome, SetBanniereHome] = useState({});

  const PrefBannierePromo = 'BANNIERE_PROMO';
  const PrefBanniereHome = 'BANNIERE_HOME';
  
  let [loading, setLoading] = useState(false);

  const editorRef = useRef(null);
  const [editEditor, setEditEditor] = useState("");
  const handleChangeEditor = (value, editor) => {
    setContent(value);
  };
  
  if(firstInit==false)
  {
    /*if(props.idVisit > 0)
    {
      setLoading(true);
      SPSGetVisite(props.idVisit, SetTheVisit);
    }*/
    DCGetSetting(PrefBannierePromo, SetBannierePromo);
    GetClassements(setListeClassements);
    GetRemises(setListeRemises);
    GetCommerciaux(setListeCommerciaux);
    GetProfils(setProfils);
    SetFirstInit(true);
  }
  
  function setListeCommerciaux(datas)
    {
        var comm = [];
        for(var i=0; i < datas.length; i++)
        {
            if(datas[i].Type == 2)
                comm.push(datas[i]);
        }

        setCommerciaux(comm);
    }


  useEffect(() => {
    
    if(stateWindow == 0)
    {
      searchDatas();
    }
    setLoading(false);
  }, [stateWindow]);

  useEffect(() => {
    if(theRecord.id != undefined)
    {
      setLoading(true);
      setPromoId(theRecord.id);
      setTitle(theRecord.Title);
      setContent(theRecord.Content);
      setInitialContent(theRecord.Content);
      setPricePrefix(theRecord.PricePrefix);
      setPrice(theRecord.Price);
      setStartDate(theRecord.StartDate.substring(0, 10));
      setEndDate(theRecord.EndDate.substring(0, 10));
      setState(theRecord.State);
      setImagePrevisu(theRecord.image);
      setClassements(theRecord.Classements);
      setATCs(theRecord.ATCs);
      setSpecialiste(theRecord.Specialiste);
      setActivite(theRecord.Activite);
      setProfil(theRecord.Profils);
      setRemiseSpecifique(theRecord.RemiseSpecifique);
      setDocument("");
      setDocumentName(theRecord.document);
      
      setStateWindow(1);
      setLoading(false);
    }
  }, [theRecord]);

  
  function searchDatas()
  {
    GetPromos(SetListe);
  }

  const handleAdd = (event) => {
    setPromoId(0);
    setTitle("");
    setContent("");
    setPricePrefix("");
    setPrice(0.0);
    setStartDate("");
    setEndDate("");
    setClassements([]);
    setATCs([]);
    setSpecialiste([]);
    setActivite([]);
    setProfil([]);
    setRemiseSpecifique(0);
    setDocument("");
    setDocumentName("");
    setState(0);
    setStateWindow(1);
  };
  const handleCancelAdd= (event) => {
    /*if(props.idVisit > 0)
      props.screenHandling(0);
    else*/
    setStateWindow(0);
  };
  const handleClick = (id) => {
    setLoading(true);
    GetPromo(id, SetTheRecord);
  };
  const handleDelete = (id) => {
    confirmAlert({
      title: 'Suppression',
      message: 'Souhaitez-vous supprimer cette promotion ?',
      buttons: [
        {
          label: 'Oui',
          onClick: () => {
            DeletePromo(id, afterDelete);
          }
        },
        {
          label: 'Non',
          onClick: () => { 

          }
        }
      ]
    });
  };

  const handleOrdreUp = (id) => {
    OrdreUp(id, SetListe);
  }

  const handleOrdreDown = (id) => {
    OrdreDown(id, SetListe);
  }
  
  const afterDelete = () => {
    searchDatas();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if(StartDate != '' && EndDate != '')
      saveData(setStateWindow);
    else
      alert("Merci de renseigner des dates de validité !");
  };

  const handleClassements = (e) => {
    var values;
    if(e.target.checked)
    {
      if(Classements.length > 0)
        values = [...Classements];
      else
        values = [];
      // on ajoute le type
      if(values.indexOf(e.target.value) < 0)
        values.push(e.target.value);
    }
    else
    {
      //on l'enleve
      values = Classements.filter(val => val != e.target.value);
    }
    setClassements(values);
  }

  const handleATCs = (e) => {
    var values;

    if(e.target.checked)
    {
      if(ATCs.length>0)
        values = [...ATCs];
      else
        values = [];
      // on ajoute le type
      if(values.indexOf(e.target.value) < 0)
        values.push(e.target.value);
    }
    else
    {
      //on l'enleve
      values = ATCs.filter(val => val != e.target.value);
    }
    setATCs(values);
  }

  const handleSpecialiste = (e) => {
    var values;

    if(e.target.checked)
    {
      if(Specialiste.length > 0)
        values = [...Specialiste];
      else
        values = [];
      // on ajoute le type
      if(values.indexOf(e.target.value) < 0)
        values.push(e.target.value);
    }
    else
    {
      //on l'enleve
      values = Specialiste.filter(val => val != e.target.value);
    }
    setSpecialiste(values);
  }

  const handleActivite = (e) => {
    var values;

    if(e.target.checked)
    {
      if(Activite.length > 0)
        values = [...Activite];
      else
        values = [];
      // on ajoute le type
      if(values.indexOf(e.target.value) < 0)
        values.push(e.target.value);
    }
    else
    {
      //on l'enleve
      values = Activite.filter(val => val != e.target.value);
    }
    setActivite(values);
  }

  const handleProfils = (e) => {
    var values;

    if(e.target.checked)
    {
      if(Profils.length > 0)
        values = [...Profils];
      else
        values = [];
      // on ajoute le type
      if(values.indexOf(e.target.value) < 0)
        values.push(e.target.value);
    }
    else
    {
      //on l'enleve
      values = Profils.filter(val => val != e.target.value);
    }
    setProfil(values);
  }

  
  const handleRSpe = (e) => {
    var values;

    if(e.target.checked)
    {
      setRemiseSpecifique(e.target.value);
      
    }
    
    
  }

  const handleGalerie = (e) => {
    readImage(e.target.files[0]);
  };
  const handleDocument = (e) => {
    const file = e.target.files[0];
    // Check if the file is an image.
    if (file.type && file.type.indexOf('application/pdf') === -1) {
      console.log('File is not a PDF.', file.type, file);
      return;
    }
    const reader = new FileReader();
    reader.addEventListener('load', (event) => {
       
        setDocument(event.target.result);
    });
    reader.readAsDataURL(file);
  };

  const handleEnleverDocument = (e) => {
    confirmAlert({
      title: 'Suppression',
      message: 'Souhaitez-vous enlever ce document ?',
      buttons: [
        {
          label: 'Oui',
          onClick: () => {
            setDocument("remove");
          }
        },
        {
          label: 'Non',
          onClick: () => { 

          }
        }
      ]
    });
    
  };

  const handleBanniere= (e) => {
    SetBannierePromo("");
    const file = e.target.files[0];
    // Check if the file is an image.
    if (file.type && file.type.indexOf('image') === -1) {
      console.log('File is not an image.', file.type, file);
      return;
    }
  
    const reader = new FileReader();
    reader.addEventListener('load', (event) => {
      // enregistre la bannière dans les prefs
      DCSaveSetting(PrefBannierePromo, {Value: "", Extra1: '', Extra2: '', file: event.target.result}, AfterSaveBanniere) ;
    });
    reader.readAsDataURL(file);
  };

  const AfterSaveBanniere = () => {
    DCGetSetting(PrefBannierePromo, SetBannierePromo);
  }

  const handleBanniereHome= (e) => {
    SetBanniereHome("");
    const file = e.target.files[0];
    // Check if the file is an image.
    if (file.type && file.type.indexOf('image') === -1) {
      console.log('File is not an image.', file.type, file);
      return;
    }
  
    const reader = new FileReader();
    reader.addEventListener('load', (event) => {
      // enregistre la bannière dans les prefs
      DCSaveSetting(PrefBanniereHome, {Value: "", Extra1: '', Extra2: '', file: event.target.result}, AfterSaveBanniereHome) ;
    });
    reader.readAsDataURL(file);
  };

  const AfterSaveBanniereHome = () => {
    DCGetSetting(PrefBanniereHome, SetBanniereHome);
  }
  
  const saveData = (handleEnd) => {
    setLoading(true);
    const body = {
      id:promoId,
      Title: Title,
      Content: Content,
      PricePrefix: PricePrefix,
      Price: Price,
      StartDate: StartDate,
      EndDate: EndDate,
      State: State,
      Classements: Classements,
      ATCs: ATCs,
      Specialiste: Specialiste,
      Activite: Activite,
      Profils: Profils,
      RemiseSpecifique: RemiseSpecifique,
      image: image,
      document: document
    };
    SavePromo(body, handleEnd);
  };


  const handleBack = (event) => {
    props.screenHandler('');
  };

  function readImage(file) {
      // Check if the file is an image.
      if (file.type && file.type.indexOf('image') === -1) {
        console.log('File is not an image.', file.type, file);
        return;
      }
    
      const reader = new FileReader();
      reader.addEventListener('load', (event) => {
         
          setImage(event.target.result);
      });
      reader.readAsDataURL(file);
  }

    switch(stateWindow)
    {
      case 1:

        return (
          <div className="container border border-primary">
            <div className="row headScreen px-0">
              <div className="col-3 text-start py-2"><button className="btn btn-secondary" type="button" onClick={handleCancelAdd}><FontAwesomeIcon icon={faChevronLeft} /> Annuler</button></div>
              <div className="col-6 text-center"><h1 className="h4 py-3">Promotion</h1></div>
              <div className="col-3 text-end py-2"><button className="btn btn-primary" type="button" onClick={handleSubmit}>Enregistrer</button></div>
            </div>
            <DCLoader load={loading} />
            <form onSubmit={handleSubmit}>
              <div className="p-3">
                <input type="hidden" name="id" value={promoId} />
                
                <div className="form-group mb-3">
                  <label id="label-chantier" >Titre</label>
                  <div className="row">
                    <div className="col-12">
                      <input type="text" name="Title" value={Title} className="form-control" onChange={e => { setTitle(e.target.value); }}/>
                    </div>
                  </div>
                </div>
                <div className="form-group mb-3">
                  <label id="label-chantier" >Etat</label>
                  <div className="row">
                    <div className="col-md-4">
                      <select name="State" className="form-select"  value={State} onChange={e => { setState(e.target.value); }}>
                        <option value="0">Brouillon</option>
                        <option value="1">Publié</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="form-group mb-3">
                  <label id="label-chantier" >Contenu</label>
                  <div className="row">
                    <div className="col-12">
                        <Editor
                            onInit={(evt, editor) => editorRef.current = editor}
                            initialValue={initialContent}
                            init={initConfig}
                            tinymceScriptSrc="/scripts/tinymce/tinymce.min.js"
                            onEditorChange={handleChangeEditor}
                        />
                    </div>
                  </div>
                </div>
                <div className="form-group row mb-3">
                  <label className="col-md-1" >Tarif</label>
                  <div className="col-md-2">
                      <input type="text" name="Price" value={Price} className="form-control" onChange={e => { setPrice(e.target.value); }}/>
                  </div>
                  <label className="col-md-1 text-end" >Préfix</label>
                  <div className="col-md-3">
                      <input type="text" name="PricePrefix" value={PricePrefix} className="form-control" onChange={e => { setPricePrefix(e.target.value); }}/>
                  </div>
                </div>
                <div className="form-group mb-3">
                  <label id="label-chantier" >Date départ</label>
                  <div className="row">
                    <div className="col-md-1">Du </div>
                    <div className="col-md-4">
                      <input type="date" name="StartDate" value={StartDate} className="form-control" required onChange={e => { setStartDate(e.target.value); }}/>
                    </div>
                    <div className="col-md-1"> Au </div>
                    <div className="col-md-4">
                      <input type="date" name="EndDate" value={EndDate} className="form-control" required onChange={e => { setEndDate(e.target.value); }}/>
                    </div>
                  </div>
                </div>
                
                <div className="form-group mb-3">
                  
                  <div className="row">
                    <div className="col-md-1">
                    <div className="card">
                      <div className="card-header">
                        ATC
                      </div>
                      <div className="card-body">
                          {commerciaux.map ((d) =>(
                          <div className="form-check" key={d.id} >
                              <input type="checkbox" className="form-check-input" value={d.id} onChange={e => {handleATCs(e)}} checked={ATCs.indexOf(d.id)>=0?"checked":""}/>
                              <label className="form-check-label">{d.FirstName.substring(0, 1)}{d.Name.substring(0, 1)}</label>
                          </div>
                          ))}
                      </div>
                    </div>
                      
                      
                    </div>
                    <div className="col-md-2">
                      <div className="card mb-2">
                        <div className="card-header">
                          Spécialiste
                        </div>
                        <div className="card-body">
                          <div className="form-check-inline"  >
                                <input type="checkbox" className="form-check-input" value="VL" onChange={e => {handleSpecialiste(e)}} checked={Specialiste.indexOf('VL')>=0?"checked":""}/>
                                <label className="form-check-label ps-2">VL</label>
                          </div>
                          <div className="form-check-inline"  >
                                <input type="checkbox" className="form-check-input" value="PL" onChange={e => {handleSpecialiste(e)}} checked={Specialiste.indexOf('PL')>=0?"checked":""}/>
                                <label className="form-check-label ps-2">PL</label>
                          </div>
                        </div>
                      </div>

                      <div className="card">
                        <div className="card-header">
                          Activité
                        </div>
                        <div className="card-body">
                          <div className="form-check"  >
                                <input type="checkbox" className="form-check-input" value="GARAGE" onChange={e => {handleActivite(e)}} checked={Activite.indexOf('GARAGE')>=0?"checked":""}/>
                                <label className="form-check-label">Garage</label>
                          </div>
                          <div className="form-check"  >
                                <input type="checkbox" className="form-check-input" value="CARROSSERIE" onChange={e => {handleActivite(e)}} checked={Activite.indexOf('CARROSSERIE')>=0?"checked":""}/>
                                <label className="form-check-label">Carrosserie</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="card">
                        <div className="card-header">
                          Profil
                        </div>
                        <div className="card-body">
                          <div className="row">
                            {profils.map ((d) =>(
                            <div className="col-md-6 form-check" key={d.Categorie} >
                                <input type="checkbox" className="form-check-input" value={d.Categorie} onChange={e => {handleProfils(e)}} checked={Profils.indexOf(d.Categorie)>=0?"checked":""}/>
                                <label className="form-check-label">{d.Categorie}</label>
                            </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="card">
                          <div className="card-header">
                            Remises
                          </div>
                          <div className="card-body">
                            {listeRemises.map ((d) =>(
                              <div className="col-md-3 form-check" key={d.id} >
                                  <input type="checkbox" className="form-check-input" value={d.id} onChange={e => {handleClassements(e)}} checked={Classements.indexOf(d.id)>=0?"checked":""}/>
                                  <label className="form-check-label">{d.Label}</label>
                              </div>
                            ))}
                          </div>
                        </div>
                    </div>
                    <div className="col-md-2">
                      <div className="card">
                          <div className="card-header">
                            Remises spécifiques
                          </div>
                          <div className="card-body">
                            
                              <div className="form-check" >
                                  <input type="radio" name="RemiseSpecifique" className="form-check-input" value="1" onChange={e => {handleRSpe(e)}} checked={RemiseSpecifique=="1"?"checked":""}/>
                                  <label className="form-check-label">Oui</label>
                              </div>
                              <div className="form-check" >
                                  <input type="radio" name="RemiseSpecifique" className="form-check-input" value="-1" onChange={e => {handleRSpe(e)}} checked={RemiseSpecifique=="-1"?"checked":""}/>
                                  <label className="form-check-label">Non (exclure les remises spécifique)</label>
                              </div>
                              <div className="form-check" >
                                  <input type="radio" name="RemiseSpecifique" className="form-check-input" value="0" onChange={e => {handleRSpe(e)}} checked={RemiseSpecifique=="0"?"checked":""}/>
                                  <label className="form-check-label">Sans effet</label>
                              </div>
                          </div>
                        </div>
                    </div>
                    
                  </div>
                </div>
                <div className="form-group mb-3">
                  <label>Image</label>
                  <button id="btnGalerie" type="button" className="btn btn-light color-primary btn-file " >
                      <label><FontAwesomeIcon icon={faImage} /><input type="file" onChange={handleGalerie} required/> Ajouter une photo</label>
                  </button>
                  <div className="previsu_image">
                  {image != "" && <div className="col-12"><img src={image} className="mw-100" /></div>}
                  {image == "" && imagePrevisu !="" && <div className="col-12"><img src={PathImagePromo(promoId)} className="mw-100" /></div>}
                  </div>
                </div>
                <div className="form-group mb-3">
                  <label>Document </label>
                  <button id="btnDocument" type="button" className="btn btn-light color-primary btn-file " >
                      <label><FontAwesomeIcon icon={faFilePdf} /><input type="file" onChange={handleDocument} required/> Ajouter un document</label>
                  </button>
                  <div className="previsu_image d-inline">
                  {document && <div className="text-success">Document prêt à être envoyé</div>}
                  {documentName  && <div className="d-inline"><a href={PathDocumentPromo(promoId)} className="btn btn-light color-primary " target="_blank">Voir le document</a> <a href="#" className="btn btn-light color-primary "  onClick={handleEnleverDocument}>Enlever</a></div>}
                  
                  </div>
                </div>
                
              </div>
              
            </form>
                      
              
            
          </div>
        );
        break;
      default:
       

        var liste_vide = "";
        if(liste.length == 0)
        {
          liste_vide = <div className="lineEmptyTab">Aucune promotion</div>
        }
        return (
          <div className="container border border-primary" >
            <div className="row headScreen">
              <div className="col-3 text-start py-2"><button className="btn btn-secondary" onClick={handleBack}><FontAwesomeIcon icon={faChevronLeft} /> Retour</button></div>
              <div className="col-6 text-center"><h1 className="h4 py-3">Promotions</h1></div>
            </div>
            <DCLoader load={loading} />
            {0==1?
            <div>
            <fieldset>
              <legend>Bannière Accueil</legend>
                <div className="form-group mb-3">
                  <button id="btnGalerie" type="button" className="btn btn-light color-primary btn-file " >
                      <label><FontAwesomeIcon icon={faImage} /><input type="file" onChange={handleBanniereHome} required/> Choisir une bannière</label>
                  </button>
                  <div className="previsu_image">
                  {BanniereHome!="" && <div className="col-12"><img src={PathDocSetting(PrefBanniereHome)} className="mw-100" /></div>}
                  </div>
                </div>
            </fieldset>
            <fieldset>
              <legend>Bannière Promo</legend>
                <div className="form-group mb-3">
                  <button id="btnGalerie" type="button" className="btn btn-light color-primary btn-file " >
                      <label><FontAwesomeIcon icon={faImage} /><input type="file" onChange={handleBanniere} required/> Choisir une bannière</label>
                  </button>
                  <div className="previsu_image">
                  {BannierePromo !="" && <div className="col-12"><img src={PathDocSetting(PrefBannierePromo)} className="mw-100" /></div>}
                  </div>
                </div>
            </fieldset>
            </div>:""}
            <fieldset>
              <legend>Promotions</legend>
            
              <table className="table">
                <tbody>
                  <tr>
                    <td className="">
                      <ul className="container">
                        
                        {liste.map((d) =>(
                        <li className="row mb-2 alternate-color py-2" key={d.id}  >
                            <div className="col-md-4" onClick={() => handleClick(d.id)}>{d.Title}</div>
                            <div className="col-md-3" onClick={() => handleClick(d.id)}>Du {d.StartDate.substring(0, 10)} au {d.EndDate.substring(0, 10)}</div>
                            <div className="col-md-3" onClick={() => handleClick(d.id)}>{d.State==0?"Brouillon":"Publié"}</div>
                            <div className="col-1 text-end">
                              <div className="btn-group-vertical me-3" role="group" >
                                <button type="button" title="Remonter la promo" className="btn btn-default btn-xs" onClick={() => handleOrdreUp(d.id)}><FontAwesomeIcon icon={faChevronUp} /></button>
                                <button type="button" title="Descendre la promo" className="btn btn-default btn-xs" onClick={() => handleOrdreDown(d.id)}><FontAwesomeIcon icon={faChevronDown} /></button>
                              </div>
                              <button type="button" className="btn btn-danger btn-sm" onClick={() => handleDelete(d.id)}><FontAwesomeIcon icon={faTrashCan} /></button>
                            </div>
                        </li>
                        ))}
                      </ul>
                      {liste_vide}
                      <div className="text-center"><button type="button" className="btn btn-primary" onClick={handleAdd}>Créer une promotion</button></div>
                      
                    </td>
                  </tr>
                </tbody>
              </table>
            </fieldset>
          </div>
        );
        break;
    }
  
}

export default ADPromos;
