import React from "react";
import { useState, useEffect } from 'react';
import MoonLoader from "react-spinners/MoonLoader";


function DCLoader({load}) {
    let [loading, setLoading] = useState(false);
    let [color, setColor] = useState("#FF0000");
    
    useEffect(() => {
        setLoading(load);
      },[load]);

    return(
        <div className="loader">
            <MoonLoader
                color={color}
                loading={loading}
                size={75}
                aria-label="CircleLoader"
                data-testid="CircleLoader"
            />
        </div>
    );
}

export default DCLoader;