
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import "../node_modules/slick-carousel/slick/slick.css"; 
import "../node_modules/slick-carousel/slick/slick-theme.css";
import './App.css';
import { useState, useEffect } from "react";
import MainNavbar from './components/Navbar';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Login from './components/Login';
import Dashboard from './components/Dashboard';
import ResetPass from './components/ResetPass';
import { DCAuthToken } from './functions/Settings';

function App() {
  const [screenOpen, setScreen] = useState('');

 const handleAfterLogin = () => {
    window.location.reload(true);
  }
  
  if(DCAuthToken(true) == "")
  {
    return (
    <BrowserRouter>
      <Routes>
        <Route path="/" >
          <Route index element={<Login screenHandling={handleAfterLogin}/>} />
          <Route path="reset/:key" element={<ResetPass />} />
          <Route path="reset/" element={<ResetPass />} />
          <Route path="create/" element={<ResetPass create="1"/>} />
        </Route>
      </Routes>
    </BrowserRouter>
    
      );
  }
  else
  {
    
    return (
      <div className="container-fluid px-0">
        <Dashboard/>
      </div>
    );
  }


  
}

export default App;
