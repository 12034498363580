import axios from "axios";
import {ActionBadToken} from "./Users.js"
const token = DCAuthToken();
const Xvisite = axios.create({
  baseURL: process.env.REACT_APP_USER_API_BASE_URL + "settings", 
  headers : { Authorization : 'Bearer ' + token }
});

export function DCClearToken()
{
    localStorage.removeItem('userAuth');
}

export function DCAuthToken(check=false)
{
    const authInfos = localStorage.getItem('userAuth');
    var tmpToken = '';
    if(authInfos)
    {
        const authObject = JSON.parse(authInfos);
        if(authObject.token)
        {
            tmpToken = authObject.token;
            // si nous sommes en ligne on vérifie le token
            if(navigator.onLine && check)
            {
                ActionBadToken(DCClearToken);
            }
        }
        
    }
    return tmpToken;
}

export async function DCGetSetting(ref, SetTheSetting)
{
    if(navigator.onLine)
    {
    const result = await Xvisite.get('/'+ref);
    SetTheSetting(result.data);
    }
  
}

export async function DCSaveSetting(ref, datas, callbackSave)
{
    if(navigator.onLine)
    {
        
        const body = {
            Value: datas.Value,
            Extra1: datas.Extra1,
            Extra2: datas.Extra2
            };
        if(datas.file != undefined)
            body.file = datas.file;

        const result = await Xvisite.put('/'+ref, body);
        callbackSave();
    } 
}

export function PathDocSetting(ref)
{
    return process.env.REACT_APP_USER_API_BASE_URL +'settings/'+ref+'/document/?token='+token;
}