import axios from "axios";
import { DCAuthToken } from "./Settings";
const token = DCAuthToken();
const XData = axios.create({
  baseURL: process.env.REACT_APP_USER_API_BASE_URL + "catalogue", 
  headers : { Authorization : 'Bearer ' + token }
});

export async function GetCatalogues(SetDatas)
{
  const result = await XData.get('/');
  SetDatas(result.data);
  
}

export async function CurrentCatalogues(SetDatas)
{
  const result = await XData.get('/current');
  SetDatas(result.data);
  
}

export async function GetCatalogue(id, SetDatas)
{
    const result = await XData.get('/'+id);
    SetDatas(result.data);
  
}

export async function SaveCatalogue(newcata, setShowForm)
{
    
  if(navigator.onLine)
  {
    var status = 0;
    // si l'utilisateur est connecté, on met à jour les données sur le serveur
    const body = {
      Title: newcata.Title, 
      Content: newcata.Content, 
      State: newcata.State,
      image: newcata.image,
      fond: newcata.fond,
      document: newcata.document
    };
    var result = [];
    if(newcata.id > 0)
    {
      result = await XData.put("/"+newcata.id, body);
      setShowForm(false);
    }
    else
    {
      result = await XData.post("/", body);
      setShowForm(false);
    }
  }
}

export async function DeleteCatalogue(id, afterDelete)
{
  const result = await XData.delete('/'+id);
  afterDelete();
  
}

export function PathImageCatalogue(id)
{
  return process.env.REACT_APP_USER_API_BASE_URL +'catalogue/image/'+id+'?token='+token;
}

export function PathDocumentCatalogue(id)
{
    return process.env.REACT_APP_USER_API_BASE_URL +'catalogue/document/'+id+'?token='+token;
}

