import DCLoader from './DCLoader';
import { useState} from 'react';
import { CurrentServices, PathImageService, PathDocumentService} from '../functions/Services';
import Slider from 'react-slick';
import BottomToolbar from './ToolbarBottom'

function Services(props) {
    const [firstInit, SetFirstInit] = useState(false);
    const [services, SetServices] = useState([]);
    const [servslider, SetSlider] = useState({});
    

    const handleClickService = (id) => {
      window.open(PathDocumentService(id));

      
    }

    const handleClickVignette = (index) => {
        //servslider.slickGoTo(index);
        if(index < services.length)
        {
            const id = services[index].id
            if(services[index].document)
            handleClickService(id);
        }

    }
    
    if(firstInit==false)
    {
        CurrentServices(SetServices);
        SetFirstInit(true);
    }
    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
      };
      
    
      
    return (
        <div id="services" className="main-content">
            
            <div className="container-xxl">
            <h1 className="text-left my-2 my-md-5 text-uppercase titre-principal">Côté services</h1>
                <div className="row mb-5">
                    <div className="col-12">
                        <table id="liste-services">
                            <tr>
                                <td>
                                    <div className="row">
                                        {services.map((d, index) =>(
                                        <div className="col-md-3 px-3  mb-3" key={d.id} onClick={() => {handleClickVignette(index)}} >
                                            <div className="vignette-service">
                                                <div className="ico_service">
                                                    <table className="w-100">
                                                        <tr>
                                                            <td className="align-middle">
                                                                <img  src={PathImageService(d.id)}  />
                                                            </td>
                                                        </tr>
                                                    </table>
                                                    
                                                </div>
                                                <div className="titre-vignette mt-2">{d.Title}</div>
                                            </div>
                                        </div>
                                        
                                        ))}
                                    </div>
                                </td>
                            </tr>
                        
                        </table>
                    </div>
                </div>
            </div>
            <BottomToolbar screenHandler={props.screenHandler}/>
            
        </div>
    );
}


export default Services;