import { useState, useEffect } from "react";
import { UserLogin } from "../functions/Users";
import { useNavigate  } from 'react-router-dom';

import logo from '../logo.png';

const Login = (props) => { 
  const [login, setLogin] = useState();
  const [password, setPassword] = useState();
  const navigate = useNavigate();

  const handleLogin = (event) => {
    event.preventDefault();
    UserLogin(login, password, props.screenHandling);
    /*axios.post(process.env.REACT_APP_USER_API_BASE_URL + "auth/login", { login: login, password: password }).then(res => {
      localStorage.setItem('userAuth', JSON.stringify(res.data));
      setIsRedirect(true);
    })*/
    
  };

  const [isRedirect, setIsRedirect] = useState(false);
  
  useEffect(() => {
    //redirectToHome();
  }, [isRedirect]);
  
  
  const loginOk = () => {
    setIsRedirect(true);
    props.screenHandling(0);
  };

  const handleClickOubli = () => {
    navigate("/reset");
  }

  const handleClickPremConnexion = () => {
    navigate("/create");
  }

  return (
    <div id="identification">
      <div className="container text-center">
        <div class="row">
          <div class="col-md-4 offset-md-4">
            <img src={logo}/>
            <h1 className="h3 my-3">Identification</h1>
            <form action="submit" onSubmit={handleLogin}>
                <input type="text" className="form-control mb-2" name="login" onChange={e => { setLogin(e.target.value); }} placeholder="Email"/>
                <input type="password" className="form-control mb-2" name="password" onChange={e => { setPassword(e.target.value); }} placeholder="Mot de passe"/>

                <button className="btn btn-primary  mb-4" type="submit" >Connexion</button>
                <br/>
                <button className="btn btn-link" type="button" onClick={handleClickOubli}>Mot de passe oublié</button>
                <br/>
                <button className="btn btn-link" type="button" onClick={handleClickPremConnexion}>Première connexion</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}



export function LoadScreen(page)
{
  //Navigate(page);
}

export default Login;
