import DCLoader from './DCLoader';
import { useState} from 'react';
import { CurrentActus, PathImageActu, PathDocumentActu } from '../functions/Actualites';
import Slider from 'react-slick';
import Modal from 'react-bootstrap/Modal';
import BottomToolbar from './ToolbarBottom'

function Actualites(props) {
    const [firstInit, SetFirstInit] = useState(false);
    const [actualites, SetActualites] = useState([]);
    const [stateModalActu, SetStateModalActu] = useState(false);
    const [selectedActu, SetSelectedActu] = useState([]);
    const handleCloseModalActu = () => {
      if(stateModalActu == true)
      {
        SetStateModalActu(false);
      }
    }

    const handleClickActu = (id) => {
      if(props.modeHome)
      {
        props.screenHandler("Actualites");
      }
      else
      {
        const p = actualites.filter((actu) => actu.id === id);
        if(p)
        {
          if(p[0].document != "")
          {
            window.open(PathDocumentActu(id));
            SetStateModalActu(false);
          }
          else
          {
            SetSelectedActu(p[0]);
            if(stateModalActu == false)
            {
              SetStateModalActu(true);
            }
          }
         
        }
      }
    }
    
    if(firstInit==false)
    {
        CurrentActus(SetActualites);
        SetFirstInit(true);
    }
    var slidesToShow = 3;
    if(actualites.length < 3)
      slidesToShow =  actualites.length;
    var settings = {
        dots: false,
        fade: true,
        infinite: true,
        autoplay: true,
        speed: 500,
        autoplaySpeed: 3000,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        responsive: [
            {
              breakpoint: 1400,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 800,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
      };
      
 /**/
 
    const titreHome=false;
    return (
        <div id="actualites" className={props.modeHome ?"home-tile":"main-content"}>
            {props.modeHome ?
            <div>
              <h2 className="text-center text-uppercase color-primary d-none">Côté actus</h2>
            </div>
            :
            <div className="container-xxl">
            <h1 className="text-left my-2 my-md-5 text-uppercase titre-principal">Côté actualites</h1>
            </div>
            }
           
            {props.modeHome?
                <Slider {...settings}>
                {actualites.map((d) =>(
                <div className="diapo text-center" key={d.id} >
                    <div className="promo-tile" onClick={() => handleClickActu(d.id)} >
                        <div className="row">
                        {d.image !="" && <div className="col-12"><img src={PathImageActu(d.id)} className="w-100" /></div>}
                        </div>
                    </div> 
                </div>
                ))}
                </Slider>
            :
            <div className="container-xxl mb-5">
                <div className="row">
                {actualites.map((d) =>(
                <div className="col-extranet text-center" key={d.id} >
                    <div className="promo-tile" onClick={() => handleClickActu(d.id)} >
                        <div className="row">
                        {d.image !="" && <div className="col-12"><img src={PathImageActu(d.id)} className="mw-100" /></div>}
                        
                        </div>
                    </div> 
                </div>
                ))}
                </div>
            </div>
            } 
            {!props.modeHome && <BottomToolbar screenHandler={props.screenHandler}/>}
            {!props.modeHome && <Modal show={stateModalActu} onHide={handleCloseModalActu} size="xl" aria-labelledby="contained-modal-title-vcenter" className="partsmenmodal" centered>
              <Modal.Body>
                <div className="row">
                  <div className="col-12 col-md-6">
                    {selectedActu.image !="" && <img src={PathImageActu(selectedActu.id)} className="w-100" />}
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="row">
                    
                      <div className="col-12 col-title text-center">
                        <h2>{selectedActu.Titre}</h2>
                      </div>
                      <div className="col-12">
                        <div dangerouslySetInnerHTML={{__html : selectedActu.Descriptif}}></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-center"><a onClick={() => {SetStateModalActu(false);}}>Fermer</a></div>
              </Modal.Body>

              
            </Modal>}
        </div>
    );
}


export default Actualites;