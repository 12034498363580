import axios from "axios";
import { DCAuthToken } from "./Settings";
const token = DCAuthToken();
const XData = axios.create({
  baseURL: process.env.REACT_APP_USER_API_BASE_URL + "classement", 
  headers : { Authorization : 'Bearer ' + token }
});

export async function GetClassements(SetDatas)
{
  const result = await XData.get('/');
  SetDatas(result.data);
}


export async function GetClassement(id, SetDatas)
{
    const result = await XData.get('/'+id);
    SetDatas(result.data);
  
}
