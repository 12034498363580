import { useState, useEffect } from "react";
import MainNavbar from './Navbar';
import ADCommerciaux from './ADCommerciaux';
import ADClients from "./ADClients";
import ADPromos from "./ADPromos";
import ADCatalogues from "./ADCatalogues";
import ADServices from "./ADServices";
import ADActualites from "./ADActualites";
import Account from "./Account";
import Promotions from "./Promotions";
import Catalogues from "./Catalogues";
import Services from "./Services";
import Actualites from "./Actualites";
import Mailbox from "./Mailbox";
import Home from "./Home";
import PageStatique from "./PagesStatiques";


const Dashboard = (props) => { 
    const [screenOpen, setScreen] = useState('');

    var content = '';
    switch(screenOpen)
    {
      case 'ADcommerciaux':
        content = <ADCommerciaux screenHandler={setScreen}/>;
        break;
      case 'ADclients':
            content = <ADClients screenHandler={setScreen}/>;
            break;
      case 'ADpromo':
            content = <ADPromos screenHandler={setScreen}/>;
            break;
      case 'ADcatalogues':
            content = <ADCatalogues screenHandler={setScreen}/>;
            break;
      case 'ADservices':
            content = <ADServices screenHandler={setScreen}/>;
            break;
      case 'ADActualites':
            content = <ADActualites screenHandler={setScreen}/>;
            break;
      case 'Account':
            content = <Account screenHandler={setScreen}/>;
            break;
      case 'Catalogues':
            content = <Catalogues screenHandler={setScreen}/>;
            break;
      case 'Services':
            content = <Services screenHandler={setScreen}/>;
            break;
      case 'Mailbox':
            content = <Mailbox screenHandler={setScreen}/>;
            break;
      case 'Promotions':
            content = <Promotions screenHandler={setScreen}/>;
            break;
      case 'Actualites':
            content = <Actualites screenHandler={setScreen}/>;
            break;
      case 'Pros':
            content = <PageStatique screenHandler={setScreen} screen="pros" />;
            break;
      case 'Pieces':
            content = <PageStatique screenHandler={setScreen} screen="pieces" />;
            break;
      case 'Livraisons':
            content = <PageStatique screenHandler={setScreen} screen="livraisons" />;
            break;
      case 'Callcenter':
            content = <PageStatique screenHandler={setScreen} screen="callcenter" />;
            break;
      default:
            content = <Home screenHandler={setScreen}/>;
            break;
    }
    
    return (
      <div>
        <MainNavbar screenHandler={setScreen}/>
        <div id="mainContent">
            {content}
        </div>
      </div>
    );
}

export default Dashboard;