import { useState, useRef, useEffect } from "react";
import { Editor } from '@tinymce/tinymce-react';
import { initConfig } from '../functions/configEditorMin';
import { SendMail } from "../functions/Mails";
import { GetUserPermissions, SearchClients, GetLabelsClients, GetCommerciaux, GetProfils } from "../functions/Users";
import { MultiSelect } from "react-multi-select-component";
import { GetClassements } from "../functions/Classements";
import { GetRemises } from "../functions/Remises";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFile, faXmark, faPaperclip } from '@fortawesome/free-solid-svg-icons'
import Modal from 'react-bootstrap/Modal';
import MailDest from "./MailDest";
import { GetCriteres } from "../functions/Criteres";

function EditMail(props) {
    const [mailobjet, setMailObjet] = useState("");
    const [content, setContent] = useState("");
    const [initialContent, setInitialContent] = useState("");
    const editorRef = useRef(null);
    const [editEditor, setEditEditor] = useState("");
    //const [classements, setClassements] = useState("");
    const [destinataires, setDestinataires] = useState([]);
    const [pjs, setPjs] = useState([]);

    const [thevalue, setTheValue] = useState("");

    const [commerciaux, setCommerciaux] = useState([]);
    const [profils, setProfils] = useState([]);
    const [listeRemises, setListeRemises] = useState([]);
    const [listecriteres, setListeCriteres] = useState([]);
  
    const [Classements, setClassements] = useState([]);
    const [ATCs, setATCs] = useState([]);
    const [Specialiste, setSpecialiste] = useState([]);
    const [Activite, setActivite] = useState([]);
    const [Profils, setProfil] = useState([]);
    const [RemiseSpecifique, setRemiseSpecifique] = useState(0);
    const [Criteres, setCriteres] = useState([]);
    const [TypeDestinataire, setTypeDestinataire] = useState(0);
    
    const [optionsClassements, setOptionsClassements] = useState([]);
    const [selectedClassements, setSelectedClassements] = useState([]);

    const [filterClient, setFilterClient] = useState("");
    const [filterRemiseSpe, setFilterRemiseSpe] = useState(false);

    const [firstInit, setFirstInit] = useState(false);
    const [userPermissions, setUserPermissions] = useState(0);

    const [destinataire, setDestinataire] = useState();

    const [searchedClients, setSearchedClients] = useState([]);

    const [stateModalClients, setStateModalClients] = useState(false);

    const [stateModalPJs, setStateModalPJs] = useState(false);

    const [labelsClients, setLabelsClients] = useState([]);

    /*const labelClassement = (id) => {
        var retour = '';
        const p = classements.filter((r) => r.id == id);
        if(p && p.length > 0)
            retour = p[0].Code;

        return retour;
    }*/

    function setListeCommerciaux(datas)
    {
        var comm = [];
        for(var i=0; i < datas.length; i++)
        {
            if(datas[i].Type == 2)
                comm.push(datas[i]);
        }

        setCommerciaux(comm);
    }

    const handleChangeEditor = (value, editor) => {
        setContent(value);
      };

    const handleSubmit = (event) => {
        event.preventDefault();
        
        const body = {
            Subject: mailobjet, 
            Content: content, 
            destinataires: destinataires,
            type_destinataire: TypeDestinataire,
            pjs: pjs
        }
        
        SendMail(body, afterSendMail);
        
    };

    const handleAnnuler = () => {
        setMailObjet("");
        setContent("");
        setInitialContent("");
        props.onSent();
    }

    const afterSendMail = () => {
        setMailObjet("");
        setContent("");
        setInitialContent("");
        props.onSent();
    };

    const handleCloseModalClients = () => {
        setStateModalClients(false);
    };

    const handleClassements = (e) => {
        var values;
    
        if(e.target.checked)
        {
          values = [...Classements];
          // on ajoute le type
          if(values.indexOf(e.target.value) < 0)
            values.push(e.target.value);
        }
        else
        {
          //on l'enleve
          values = Classements.filter(val => val != e.target.value);
        }
        setClassements(values);
      }

    const handleATCs = (e) => {
        var values;

        if(e.target.checked)
        {
            values = [...ATCs];
            // on ajoute le type
            if(values.indexOf(e.target.value) < 0)
            values.push(e.target.value);
        }
        else
        {
            //on l'enleve
            values = ATCs.filter(val => val != e.target.value);
        }
        setATCs(values);
    }

    const handleSpecialiste = (e) => {
        var values;

        if(e.target.checked)
        {
            values = [...Specialiste];
            // on ajoute le type
            if(values.indexOf(e.target.value) < 0)
            values.push(e.target.value);
        }
        else
        {
            //on l'enleve
            values = Specialiste.filter(val => val != e.target.value);
        }
        setSpecialiste(values);
    }

    const handleActivite = (e) => {
        var values;

        if(e.target.checked)
        {
            values = [...Activite];
            // on ajoute le type
            if(values.indexOf(e.target.value) < 0)
            values.push(e.target.value);
        }
        else
        {
            //on l'enleve
            values = Activite.filter(val => val != e.target.value);
        }
        setActivite(values);
    }

    const handleProfils = (e) => {
        var values;

        if(e.target.checked)
        {
            values = [...Profils];
            // on ajoute le type
            if(values.indexOf(e.target.value) < 0)
            values.push(e.target.value);
        }
        else
        {
            //on l'enleve
            values = Profils.filter(val => val != e.target.value);
        }
        setProfil(values);
    }

    
    const handleRSpe = (e) => {
        var values;

        if(e.target.checked)
        {
            setRemiseSpecifique(e.target.value);
            
        }
    
    
    }

    const handleCriteres = (e) => {
        var values;

        if(e.target.checked)
        {
            values = [...Criteres];
            // on ajoute le type
            if(values.indexOf(e.target.value) < 0)
            values.push(e.target.value);
        }
        else
        {
            //on l'enleve
            values = Criteres.filter(val => val != e.target.value);
        }
        setCriteres(values);
    }

    const searchClients = () => {
        if(filterClient != '' || ATCs.length > 0 || Specialiste.length > 0 || Activite.length > 0 || Profils.length > 0 || Classements.length > 0 || RemiseSpecifique != ''  || Criteres.length > 0)
        {
            SearchClients(filterClient, ATCs, Specialiste, Activite, Profils, Classements, RemiseSpecifique, Criteres, setSearchedClients) ;//(filterClient, selectedClassements, filterRemiseSpe, setSearchedClients);
        }
        else
            setSearchedClients([]);
    };

    
    const handleAddDestinataire = (id) => {
        const test = destinataires.filter((r) => r == id);
        if(test.length == 0)
        {
            var dests = [...destinataires];
            dests.push(id);

            setDestinataires(dests);
        }
    };

    const handleAddAllSelection = () => {
        var dests = [...destinataires];
        for(var i=0;i < searchedClients.length;i++)
        {
            const test = destinataires.filter((r) => r == searchedClients[i].id);
            if(test.length == 0)
            {
                dests.push(searchedClients[i].id);
            }
        }
        setDestinataires(dests);
    }

    const handleCloseModalPJs = () => {
        setStateModalPJs(false);
    };

    if(firstInit == false)
    {
        GetUserPermissions(setUserPermissions);
        GetRemises(setListeRemises);
        GetLabelsClients(setLabelsClients);
        GetCommerciaux(setListeCommerciaux);
        GetProfils(setProfils);
        GetCriteres(setListeCriteres);
        setFirstInit(true);
    }
    const handleRemoveDest = (id) => {
        var newdests = destinataires.filter((r) => r != id);
        setDestinataires(newdests);
    }

    const getLibClient = (id) => {
        var retour = '';
        var client = labelsClients.filter((r) => r.id == id);
        if(client && client.length > 0)
            retour = client[0].label;
        
        return retour;
    };

    const handleAddPJ = (e) => {
        //setStateModalPJs(true);
        if(e.target.files.length > 0)
            readFile(e.target.files[0])
    }

    const handleRemoveFile = (id) => {
        var newpjs = pjs.filter((r) => r.id != id);
        setPjs(newpjs);
    }

    const handleChangeTypeDestinataire = (id) => {
        setTypeDestinataire(id);
    }

    const getListeDestinataire = () => {
       /* var liste_tmp = '';
        for(var i=0; i < destinataires.length; i++)
        {
            liste_tmp+= 
        }
        return liste_tmp;*/
        if(destinataires.length > 0)
        {
            return destinataires.map((d) =>(
                <MailDest key={d} id={d} label={getLibClient(d)} onRemove={handleRemoveDest}/>
                ));
        }
        else
            return '';

    }

    useEffect(() => {
        switch(userPermissions)
        {
            // administrateur
            case '1':
            // commercial
            case '2':
                // les admin et les commerciaux ont le droit de choisir les destinataires parmis les clients (par nom ou par catégorie de chiffre d'affaire)
                // la seule restriction pour les commerciaux et qu'ils ne peuvent envoyer qu'aux clients qui leur sont affectés
                
                setDestinataire(<div><button type="button" className="btn btn-primary" onClick={() => {setStateModalClients(true);}}>Ajouter...</button> {getListeDestinataire()}</div>)
                break;
            // client
            case '3':
                var namedest = 'Mon commercial';
                if(props.draft.destinataire_name!= undefined && props.draft.destinataire_name!='')
                {
                    namedest = props.draft.destinataire_name;
                    // le client n'a pas le choix, il ne peux envoyer qu'à son commercial
                    setDestinataire(<span className="text-muted">{namedest}</span>);
                }
                else
                {
                    setDestinataire(
                        <select className="form-select" onChange={e => handleChangeTypeDestinataire(e.target.value)}>
                            <option value="0" >Mon commercial</option>
                            <option value="1" >Administrateur</option>
                        </select>);
                }
        }
      }, [userPermissions, destinataires]); 
    
    /*useEffect(() => {
        var tabClassements = [];
        for(var i=0; i < classements.length; i++)
        {
            tabClassements.push({label: classements[i].Label, value: classements[i].id});
        }
        setOptionsClassements(tabClassements);

      }, [classements]); */
    
    useEffect(() => {
        searchClients();
    }, [filterClient, filterRemiseSpe, ATCs, Specialiste, Activite, Profils, Classements, Criteres]);

    useEffect(() => {
        if(props.draft.destinataire)
            setDestinataires([props.draft.destinataire]);
        else
        setDestinataires([]);
        if(props.draft.objet != undefined)
            setMailObjet(props.draft.objet);
        if(props.draft.content != undefined)
        {
            setContent(props.draft.content);
            setInitialContent(props.draft.content);
        }
    }, [props.draft]);

    function readFile(file) {
        // Check if the file is an image.
        /*if (file.type && file.type.indexOf('image') === -1) {
            console.log('File is not an image.', file.type, file);
            return;
        }*/
        
        const reader = new FileReader();
        reader.fileName = file.name;
        reader.addEventListener('load', (event) => {
             // copie du state
            const pjsCopy = [...pjs];
            const p = {id:(new Date().getTime()) * -1, File: event.target.result, Name: event.target.fileName}
            
            pjsCopy.push(p);
            // modifie le state original
            setPjs(pjsCopy);

        });
        reader.readAsDataURL(file);
    }

    // si l'utilisateur est un commercial, il a le droit de choisir parmis ses clients
    return (
        <div className="container">
            <form onSubmit={handleSubmit}>
                <div className="row mb-2">
                    <div className="col-md-2" ><label className="form-label">Destinataire</label></div>
                    <div className="col-md-10">{destinataire}</div>
                </div>
                <div className="row mb-2">
                    <div className="col-md-2" ><label className="form-label">Objet</label></div>
                    <div className="col-md-8">
                        <input type="text" className="form-control" value={mailobjet} onChange={e => {setMailObjet(e.target.value)}} required/>
                    </div>
                    <div className="col-md-2" >
                        <button id="btnPJ" type="button" className="btn btn-primary btn-file" >
                            <label>Joindre un fichier<input type="file" onChange={handleAddPJ} required/></label>
                        </button>
                    </div>
                </div>
                {pjs.length > 0?
                <div className="mb-2">
                    {pjs.map((d) =>(
                    <div className="d-inline-block case-pj" key={d.id}  >
                        <span className="h5"><FontAwesomeIcon icon={faPaperclip} /></span>&nbsp;{d.Name}
                        <div onClick={() => {handleRemoveFile(d.id)}} className="closeButton"><FontAwesomeIcon icon={faXmark} /></div>
                    </div>
                    
                        ))}
                </div>:''}
                <div className="row mb-2">
                    <label className="col-12 form-label">Message</label>
                    <div className="col-12">
                    <Editor
                                onInit={(evt, editor) => editorRef.current = editor}
                                initialValue={initialContent}
                                init={initConfig}
                                tinymceScriptSrc="/scripts/tinymce/tinymce.min.js"
                                onEditorChange={handleChangeEditor}
                            />
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-6"><button type="button" className="btn btn-default" onClick={handleAnnuler}>Annuler</button></div>
                    <div className="col-6 text-end"><button type="submit" className="btn btn-primary" onClick={handleSubmit}>Envoyer</button></div>
                </div>
            </form>
            <Modal show={stateModalClients} onHide={handleCloseModalClients} size="xl" aria-labelledby="contained-modal-title-vcenter" className="partsmenmodal" centered>
              <Modal.Body>
                <div className="row mb-2">
                  <div className="col-6">
                    <input type="text" className="form-control" value={filterClient} onChange={e => { setFilterClient(e.target.value); }} placeholder="Rechercher un client"/>
                  </div>
                  <div className="col-6">
                    {listecriteres.map((d) =>(
                    <div className="form-check-inline" key={d.id} >
                          <input type="checkbox" className="form-check-input" value={d.id} onChange={e => {handleCriteres(e)}} checked={Criteres.indexOf(d.id)>=0?"checked":""}/>
                          <label className="form-check-label">&nbsp;{d.Critere}</label>
                    </div>
                          ))}
                    
                  </div>
                  
                </div>
                <div className="form-group mb-3">
                  
                  <div className="row">
                    <div className="col-md-2">
                    <div className="card">
                      <div className="card-header">
                        ATC
                      </div>
                      <div className="card-body filter-list">
                          <div className="form-check" >
                              <input type="checkbox" className="form-check-input" value="0" onChange={e => {handleATCs(e)}} checked={ATCs.indexOf("0")>=0?"checked":""}/>
                              <label className="form-check-label">Sans ATC</label>
                          </div>
                          {commerciaux.map ((d) =>(
                          <div className="form-check" key={d.id} >
                              <input type="checkbox" className="form-check-input" value={d.id} onChange={e => {handleATCs(e)}} checked={ATCs.indexOf(d.id)>=0?"checked":""}/>
                              <label className="form-check-label">{d.FirstName.substring(0, 1)}{d.Name.substring(0, 1)}</label>
                          </div>
                          ))}
                      </div>
                    </div>
                      
                      
                    </div>
                    <div className="col-md-2">
                      <div className="card mb-2">
                        <div className="card-header">
                          Spécialiste
                        </div>
                        <div className="card-body filter-list">
                          <div className="form-check-inline"  >
                                <input type="checkbox" className="form-check-input" value="VL" onChange={e => {handleSpecialiste(e)}} checked={Specialiste.indexOf('VL')>=0?"checked":""}/>
                                <label className="form-check-label">&nbsp;VL</label>
                          </div>
                          <div className="form-check-inline"  >
                                <input type="checkbox" className="form-check-input" value="PL" onChange={e => {handleSpecialiste(e)}} checked={Specialiste.indexOf('PL')>=0?"checked":""}/>
                                <label className="form-check-label">&nbsp;PL</label>
                          </div>
                        </div>
                      </div>

                      <div className="card">
                        <div className="card-header">
                          Activité
                        </div>
                        <div className="card-body filter-list">
                          <div className="form-check"  >
                                <input type="checkbox" className="form-check-input" value="GARAGE" onChange={e => {handleActivite(e)}} checked={Activite.indexOf('GARAGE')>=0?"checked":""}/>
                                <label className="form-check-label">Garage</label>
                          </div>
                          <div className="form-check"  >
                                <input type="checkbox" className="form-check-input" value="CARROSSERIE" onChange={e => {handleActivite(e)}} checked={Activite.indexOf('CARROSSERIE')>=0?"checked":""}/>
                                <label className="form-check-label">Carrosserie</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="card">
                        <div className="card-header">
                          Profil
                        </div>
                        <div className="card-body filter-list">
                          <div className="row">
                            {profils.map ((d) =>(
                            <div className="col-md-6 form-check" key={d.Categorie} >
                                <input type="checkbox" className="form-check-input" value={d.Categorie} onChange={e => {handleProfils(e)}} checked={Profils.indexOf(d.Categorie)>=0?"checked":""}/>
                                <label className="form-check-label">{d.Categorie}</label>
                            </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="card">
                          <div className="card-header">
                            Remises
                          </div>
                          <div className="card-body filter-list">
                            {listeRemises.map ((d) =>(
                              <div className="col-md-3 form-check" key={d.id} >
                                  <input type="checkbox" className="form-check-input" value={d.id} onChange={e => {handleClassements(e)}} checked={Classements.indexOf(d.id)>=0?"checked":""}/>
                                  <label className="form-check-label">{d.Label}</label>
                              </div>
                            ))}
                          </div>
                        </div>
                    </div>
                    <div className="col-md-2">
                      <div className="card">
                          <div className="card-header">
                            Remises spécifiques
                          </div>
                          <div className="card-body filter-list">
                            
                              <div className="col-md-3 form-check" >
                                  <input type="radio" name="RemiseSpecifique" className="form-check-input" value="1" onChange={e => {handleRSpe(e)}} checked={RemiseSpecifique=="1"?"checked":""}/>
                                  <label className="form-check-label">Oui (inclure)</label>
                              </div>
                              <div className="col-md-3 form-check" >
                                  <input type="radio" name="RemiseSpecifique" className="form-check-input" value="-1" onChange={e => {handleRSpe(e)}} checked={RemiseSpecifique=="-1"?"checked":""}/>
                                  <label className="form-check-label">Non (exclure)</label>
                              </div>
                           
                          </div>
                        </div>
                    </div>
                    
                  </div>
                </div>
                <div id="liste-searched-clients" className="border border-primary mt-3">
                    <div className="container">
                        {searchedClients.map((d) =>(
                        <div key={d.id} className="row py-1 alternate-color">
                            <div className="col-8">
                                {d.Company}
                            </div>
                            
                            <div className="col-1 text-end">
                                {destinataires.includes(d.id)==false?<button className="btn btn-default btn-sm" onClick={() => {handleAddDestinataire(d.id)}} >+</button>:''}
                            </div>
                        </div>
                        ))}
                        {searchedClients.length == 0?<div className="text-center text-muted py-4">Aucun client trouvé</div>:""}
                    </div>
                </div>
                <div className="text-end mt-4"><button type="button" className="btn btn-default" onClick={() => {handleAddAllSelection()}} >Ajouter toute la selection</button></div>
              </Modal.Body>
            </Modal>

            <Modal show={stateModalPJs} onHide={handleCloseModalPJs} aria-labelledby="contained-modal-title-vcenter" className="partsmenmodal" centered>
              <Modal.Body>
                
              </Modal.Body>
            </Modal>
        </div>
    );
}

export default EditMail;