import axios from "axios";
import { DCAuthToken } from "./Settings";
const token = DCAuthToken();
const XData = axios.create({
  baseURL: process.env.REACT_APP_USER_API_BASE_URL + "service", 
  headers : { Authorization : 'Bearer ' + token }
});

export async function GetServices(SetDatas)
{
  const result = await XData.get('/');
  SetDatas(result.data);
}

export async function CurrentServices(SetDatas)
{
  const result = await XData.get('/current');
  SetDatas(result.data);
  
}

export async function GetService(id, SetDatas)
{
    const result = await XData.get('/'+id);
    SetDatas(result.data);
  
}

export async function SaveService(newservice, setShowForm)
{
    
  if(navigator.onLine)
  {
    var status = 0;
    // si l'utilisateur est connecté, on met à jour les données sur le serveur
    const body = {
      Title: newservice.Title, 
      Content: newservice.Content, 
      State: newservice.State,
      image: newservice.image,
      fond: newservice.fond,
      document: newservice.document
    };
    var result = [];
    if(newservice.id > 0)
    {
      result = await XData.put("/"+newservice.id, body);
      setShowForm(false);
    }
    else
    {
      result = await XData.post("/", body);
      setShowForm(false);
    }
  }
}

export async function DeleteService(id, afterDelete)
{
  const result = await XData.delete('/'+id);
  afterDelete();
  
}

export function PathImageService(id)
{
  return process.env.REACT_APP_USER_API_BASE_URL +'service/image/'+id+'?token='+token;
}

export function PathDocumentService(id)
{
    return process.env.REACT_APP_USER_API_BASE_URL +'service/document/'+id+'?token='+token;
}

