import DCLoader from './DCLoader';
import { useState} from 'react';
import { CurrentCatalogues, PathImageCatalogue, PathDocumentCatalogue } from '../functions/Catalogues';
import Slider from 'react-slick';
import BottomToolbar from './ToolbarBottom'

function Promotions(props) {
    const [firstInit, SetFirstInit] = useState(false);
    const [catalogues, SetCatalogues] = useState([]);
    const [catslider, SetSlider] = useState({});
    

    const handleClickCatalogue = (id) => {
      window.open(PathDocumentCatalogue(id));

      
    }
    
    if(firstInit==false)
    {
        CurrentCatalogues(SetCatalogues);
        SetFirstInit(true);
    }
    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
      };
      
    
      
    return (
        <div id="catalogues" className="main-content">
            
            
            <div className="container-xxl px-0">
            <h1 className="text-left my-2 my-md-5 text-uppercase titre-principal">Côté catalogues</h1>
                
                <div className="text-center px-4">
                    <div className="row">
                        {catalogues.map((d, index) =>(
                        
                        <div className="col col-12 col-md-3 px-1 px-md-0 vignette-catalogue mb-5" key={d.id}  >
                            <img className="mw-100" onClick={() => {handleClickCatalogue(d.id)}} src={PathImageCatalogue(d.id)}  />
                        </div>
                        
                        ))}
                    </div>
                </div>
            </div>
            
            <BottomToolbar screenHandler={props.screenHandler}/>
        </div>
    );
}


export default Promotions;