import axios from "axios";
import { DCAuthToken } from "./Settings";
const token = DCAuthToken();
const XUsers = axios.create({
  baseURL: process.env.REACT_APP_USER_API_BASE_URL + "user", 
  headers : { Authorization : 'Bearer ' + token }
});

const XAuth = axios.create({
  baseURL: process.env.REACT_APP_USER_API_BASE_URL + "auth", 
  headers : { Authorization : 'Bearer ' + token }
});


export async function UserLogin(login, password, afterLogin)
{
  const body = {login: login, password: password};
  const res = await XAuth.post('/login/', body);
  if(res.data.token)
  {
    localStorage.setItem('userAuth', JSON.stringify(res.data));
    afterLogin();
  }
}

export async function Logout(afterLogout)
{
  const res = await XAuth.get('/logout/');
  if(res.data.logout)
  {
    localStorage.removeItem('userAuth');
    afterLogout();
  }
}

export async function GetUserPermissions(returnUserPermissions)
{
    if(navigator.onLine)
    {
      const result = await XUsers.get('/permissions/');
      returnUserPermissions(result.data.permissions);
    }
}

export async function GetUsers(SetUsers)
{
  const result = await XUsers.get('/');
   SetUsers(result.data);
  
}

export async function GetCommerciaux(SetUsers)
{
  const result = await XUsers.get('/commerciaux');
  SetUsers(result.data);
}

export async function GetClients(SetUsers)
{
  const result = await XUsers.get('/clients');
  SetUsers(result.data);
}

export async function GetProfils(SetProfils)
{
  const result = await XUsers.get('/profils');
  SetProfils(result.data);
}

export async function SearchClients(search, atc, specialiste, activite, profil, remises, remiseSpe, criteres, SetUsers)
{
  const body = {
    search: search, 
    atc: atc,
    specialiste: specialiste,
    activite: activite,
    profil: profil,
    remises: remises,
    remiseSpe: remiseSpe,
    criteres: criteres
  };
  const result = await XUsers.post('/searchclients', body);
  SetUsers(result.data);
}

export async function GetUser(id, SetUser)
{
  const result = await XUsers.get('/'+id);
  SetUser(result.data);
  
}

export async function DeleteUser(id, afterDelete)
{
  const result = await XUsers.delete('/'+id);
  afterDelete();
  
}

export async function SaveUser(newuser, setShowForm)
{
  if(navigator.onLine)
  {
    var status = 0;
    // si l'utilisateur est connecté, on met à jour les données sur le serveur
    const body = {
      Active: newuser.Active, 
      Type: newuser.Type, 
      FirstName: newuser.FirstName, 
      Name: newuser.Name, 
      Poste: newuser.Poste, 
      Email: newuser.Email,
      MailSignature: newuser.MailSignature, 
      Password: newuser.Password,
      Magasins: newuser.Magasins
    };
    var result = [];
    if(newuser.id > 0)
    {
      result = await XUsers.put("/"+newuser.id, body);
      setShowForm(false);
    }
    else
    {
      result = await XUsers.post("/", body);
      setShowForm(false);
    }
    if(result.data.length > 0 && result.data[0].id != undefined)
      status = 1;
  }
  else
    setShowForm(false);
  
}

export async function SaveClient(newuser, setShowForm)
{
  if(navigator.onLine)
  {
    var status = 0;
    // si l'utilisateur est connecté, on met à jour les données sur le serveur
    const body = {
      Active: newuser.Active, 
      Type: newuser.Type, 
      Code: newuser.Code, 
      IdMagasin: newuser.IdMagasin,
      IdCommercial: newuser.IdCommercial, 
      IdClassement: newuser.IdClassement,
      IdRemise1: newuser.IdRemise1,
      IdRemise2: newuser.IdRemise2,
      RemiseSpecifique : newuser.RemiseSpecifique,
      Enseigne: newuser.Enseigne,  
      FirstName: newuser.FirstName, 
      Name: newuser.Name, 
      Poste: newuser.Poste, 
      Email: newuser.Email,
      MailSignature: newuser.MailSignature, 
      Password: newuser.Password,
      Company: newuser.Company,
      Phone: newuser.Phone,
      Mobile: newuser.Mobile,
      Address: newuser.Address,
      Address2: newuser.Address2,
      Zip: newuser.Zip,
      City: newuser.City,
      Activite_garage: newuser.Activite_garage,
      Activite_carrosserie: newuser.Activite_carrosserie,
      Activite_atelier: newuser.Activite_atelier,
      Specialiste_PL: newuser.Specialiste_PL,
      Specialiste_VL: newuser.Specialiste_VL,
      Nb_Apprentis: newuser.Nb_Apprentis,
      Categorie: newuser.Categorie,
      Equipements: newuser.Equipements,
      Criteres: newuser.Criteres,
      CyberActive: newuser.CyberActive

    };
    var result = [];
    if(newuser.id > 0)
    {
      result = await XUsers.put("/"+newuser.id, body);
      setShowForm(false);
    }
    else
    {
      result = await XUsers.post("/", body);
      setShowForm(false);
    }
    if(result.data.length > 0 && result.data[0].id != undefined)
      status = 1;
  }
  else
    setShowForm(false);
  
}

export async function UserQuickList(SetUsers)
{
  const result = await XUsers.get('/list/');
  SetUsers(result.data);
  
}

export async function ActionBadToken(actionKO)
{
  // si nous sommes en ligne on vérifie le token
  if(navigator.onLine)
  {
    var t = DCAuthToken();
    if(t != "")
    {
      const result = await XUsers.get('/checktoken/');
      
      if(result.data.ok == 0)
      {
        actionKO();
      }
    }
  }
}

export async function UserDisplayName(setDisplayName, setType, setCyberActive)
{
  if(navigator.onLine)
  {
    const result = await XUsers.get('/displayname/');
    setDisplayName(result.data.name);
    setType(result.data.Type);
    setCyberActive(result.data.cyber);
    
  }
}

export async function CyberAccess(setCyberAccess)
{
  const result = await XUsers.get('/cyber/');
  setCyberAccess(result.data.user, result.data.pass);
}

export async function SaveAccount(body, afterSave)
{
  if(navigator.onLine)
  {
    await XUsers.put("/account/", body);
      
    afterSave(0);
  }
}

export async function GetAccount(loadAccount)
{
  if(navigator.onLine)
  {
    const result = await XUsers.get("/account/");
    loadAccount(result.data);
  }
}

export async function ValidResetPassword(key, password, returnFunction)
{
  if(navigator.onLine)
  {
    const body = {
      key: key, 
      password: password
    }
    const result = await XAuth.post("/validreset/", body);
    returnFunction(result.data.ok);
  }
}

export async function ResetPassword(email, returnFunction)
{
  if(navigator.onLine)
  {
    const body = {
      email: email}
    const result = await XAuth.post("/reset/", body);
    returnFunction(result.data.ok);
  }
}

export async function CheckKeyReset(key, loadInterface)
{
  if(navigator.onLine)
  {
    const result = await XAuth.get("/checkreset/"+key);
    loadInterface(result.data.ok);
  }
}

export async function MailReinitPassword(id, afterReinit)
{
  if(navigator.onLine)
  {
    const result = await XUsers.get("/mailreinit/"+id);
    afterReinit(result.data.ok);
  }
}

export async function GetLabelsClients(setLabels)
{
  const result = await XUsers.get("/labels/");
  setLabels(result.data);
}

