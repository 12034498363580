import { useState, useEffect, useRef } from 'react';
import { confirmAlert } from 'react-confirm-alert'; // Import

import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import Dropdown from 'react-bootstrap/Dropdown';
import DCLoader from './DCLoader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faReply, faPaperclip, faTrash } from '@fortawesome/free-solid-svg-icons';
import { GetMail, GetMails, GetSentmails, GetReplyMail, OpenPJ, DeleteMessage } from '../functions/Mails';
import { GetCommerciaux} from '../functions/Users';
import { GetUserPermissions} from "../functions/Users";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import EditMail from './EditMail';


function Mailbox(props) {

  const [firstInit, SetFirstInit] = useState(false);
  const [stateWindow, setStateWindow] = useState(0);
  const [liste, SetListe] = useState([]);
  const [listeSent, SetListeSent] = useState([]);
  const [theRecord, SetTheRecord] = useState([]);
  const [mailSent, SetMailSent] = useState({});
  const [userPermissions, setUserPermissions] = useState(0);
  const [listeCommerciaux, setCommerciaux] = useState([]);
  const [selectCommerciaux, setSelectCommerciaux] = useState('');
  const [currentCommercial, setCurrentCommercial] = useState(0);
  const [currentCommercialLabel, setCurrentCommercialLabel] = useState('Moi');
  
  const [mailId, setMailId] = useState(0);
  const [Objet, setObjet] = useState("");
  const [Content, setContent] = useState("");
  const [pjs, setPjs] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);

  const [draftMail, setDraftMail] = useState({});
  
  
  let [loading, setLoading] = useState(false);

  
  if(firstInit==false)
  {
    /*if(props.idVisit > 0)
    {
      setLoading(true);
      SPSGetVisite(props.idVisit, SetTheVisit);
    }*/
    GetUserPermissions(setUserPermissions);
    SetFirstInit(true);
  }
  


  useEffect(() => {
    
    if(stateWindow == 0)
    {
      searchDatas();
    }
    setLoading(false);
  }, [stateWindow]);

  useEffect(() => {
    if(theRecord.id != undefined)
    {
      setLoading(true);
      setMailId(theRecord.id);
      setObjet(theRecord.Subject);
      setContent(theRecord.Content);
      setPjs(theRecord.pjs);
      
      setStateWindow(1);
      setLoading(false);
    }
  }, [theRecord]);

  
  useEffect(() => {
    if(mailSent.id != undefined)
    {
      setLoading(false);
    }
  }, [mailSent]);

  useEffect(() => {
    if(userPermissions == 1)
    {
      // si nous sommes un administrateur, on a la liste des boites des commerciaux en lecture
      GetCommerciaux(setCommerciaux);
    }
    else
    {
      setCommerciaux([]);
    }
    
    
  }, [userPermissions]); 

  useEffect(() => {
      if(listeCommerciaux.length > 0)
      {
        setSelectCommerciaux(
          <Dropdown>
            <Dropdown.Toggle variant="primary" id="dropdown-basic">
              {currentCommercialLabel}
            </Dropdown.Toggle>
    
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => handleChangeCommercial(0, 'Moi')}>Moi</Dropdown.Item>
              {listeCommerciaux.map((c) =>(
                  <Dropdown.Item key={c.id} onClick={() => handleChangeCommercial(c.id, c.FirstName+' '+c.Name)}>{c.FirstName} {c.Name}</Dropdown.Item>
              ))}
              
            </Dropdown.Menu>
          </Dropdown>
          
          
        );
      }
      else
      setSelectCommerciaux("");
      searchDatas();
  }, [listeCommerciaux, currentCommercial]); 

  const handleChangeCommercial = (id, label) =>  {
    setCurrentCommercial(id);
    setCurrentCommercialLabel(label);
    SetTheRecord([]);
    
  }
  
  function searchDatas()
  {
    GetMails(currentCommercial, SetListe);
    GetSentmails(currentCommercial, SetListeSent);
  }

 
  const handleClick = (id) => {
    setLoading(true);
    GetMail(id, SetTheRecord);
  };

  const handleClickSent = (id) => {
    setLoading(true);
    GetMail(id, SetMailSent);
  }
  const handleReply = (id) => {
    GetReplyMail(id, setReplyContent);
  }

  const setReplyContent = (datas) => {
    setDraftMail({destinataire: datas.Destinataire, destinataire_name: datas.DestinataireName, objet: datas.Subject, content : datas.Content});
    setTabIndex(2);
  }

  const handleClickPj = (idMail, pjfile) =>
  {
    OpenPJ(idMail, pjfile);
  }
  
  const afterDelete = () => {
    searchDatas();
  };
  const handleDeleteMessage = (e, id) => {
    e.stopPropagation();
    confirmAlert({
      title: 'Suppression',
      message: 'Souhaitez-vous supprimer cet message ?',
      buttons: [
        {
          label: 'Oui',
          onClick: () => {
            DeleteMessage(id, searchDatas);
          }
        },
        {
          label: 'Non',
          onClick: () => { 

          }
        }
      ]
    });
    
  }
  const handleSubmit = (event) => {
    event.preventDefault();
    saveData(setStateWindow);
  };
  
  const saveData = (handleEnd) => {
    setLoading(true);
    const body = {
      id:mailId,
      Subject: Objet,
      Content: Content,
    };
    
    //SaveMail(body, handleEnd);
  };

  function formatedatemail(datemail)
  {
    var date = new Date(datemail);
    var retour = "";

    //retour.date = date.getDate().toFixed(2)+"/"+(date.getMonth()+1)+"/"+date.getFullYear();
    var datestring = date.toLocaleDateString('fr-FR');
    var heure = date.getHours()+":"+date.getMinutes();

    if(datestring != new Date().toLocaleDateString('fr-FR'))
      retour = datestring;
    else
      retour = heure;


    return retour;
  }

  const handleMessageSent = () => {
    GetSentmails(currentCommercial, SetListeSent);
    setDraftMail({});
    SetTheRecord([]);
    setTabIndex(0);
  };

    var listevide = '';
    if(liste.length == 0)
    {
        listevide = <div className="text-center text-muted fst-italic">Aucun message</div>
    }
    
    return (
          <div className="container border border-primary content-full-height py-3" >
            <DCLoader load={loading} />
            {selectCommerciaux}
            <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)} className="h-100">
                <TabList>
                  <Tab>Boite de réception</Tab>
                  <Tab>Envoyés</Tab>
                  {currentCommercial==0?<Tab>Ecrire message</Tab>:''}
                </TabList>
                
                <TabPanel>
                    <div className="row h-100">
                        <div className="col-md-4 border-end border-primary mail-list">
                            {liste.map((d) =>(
                            <li className={theRecord.id==d.id?"row mx-1 mb-2 alternate-color py-2 bg-primary":"row mx-1 mb-2 alternate-color py-2"} key={d.id} onClick={() => handleClick(d.id)} >
                                <div className="col-9" ><h5 className="mb-0">{d.FirstName!= ""?d.FirstName+' '+d.Name:d.Company}</h5>{d.Subject}</div>
                                <div className="col-3 text-end" >
                                  {formatedatemail(d.SentDate)}
                                  <a className="bt-delete-message" onClick={e => handleDeleteMessage(e, d.id)}><FontAwesomeIcon icon={faTrash} /></a>
                                </div>
                            </li>
                            ))}
                            {listevide}
                        </div>
                        <div className="col-md-8">
                            {theRecord.id?<div>
                                    <div className="row">
                                      <div className="col-9">
                                       <label>Expéditeur :&nbsp;</label><em>{theRecord.Expediteur}</em><br />
                                        <h5>{theRecord.Subject}</h5>
                                      </div>
                                      <div className="col-3 text-end">
                                        <div className="mb-2x">{currentCommercial==0?<button type="button" className="btn btn-default" onClick={() => {handleReply(theRecord.id)}}><FontAwesomeIcon icon={faReply} /> Répondre</button>:''}</div>
                                      </div>
                                    </div>
                                    
                                    
                                    {theRecord.pjs.length > 0?
                                    <div className="mb-2">
                                      {theRecord.pjs.map((d) =>(
                                        <div className="d-inline-block case-pj" key={d.id}  onClick={() => {handleClickPj(theRecord.id, d.Name);}}>
                                            <span className="h5" ><FontAwesomeIcon icon={faPaperclip} /></span>&nbsp;{d.Name}
                                        </div>
                                        ))}
                                    </div>
                                    :''}
                                    <div className="border p-2 mt-3"><div dangerouslySetInnerHTML={{__html :theRecord.Content}}></div></div>
                                </div>:<div className="text-center text-muted">Vous devez sélectionner un message</div>}
                        </div>
                        <div className="col-md-2">

                        </div>
                    </div>
                </TabPanel>
                <TabPanel>
                    <div className="row h-100">
                        <div className="col-md-4 border-end border-primary mail-list">
                            {listeSent.map((d) =>(
                              
                            <li className={mailSent.id==d.id?"row mx-1 mb-2 alternate-color py-2 bg-primary":"row mx-1 mb-2 alternate-color py-2"} key={d.id} onClick={() => handleClickSent(d.id)} >
                                <div className="col-9" ><h5 className="mb-0">
                                  {d.Destinataires.map((user) => (
                                    <span className="me-2" key={user.id}>{user.FirstName!= ""?user.FirstName+' '+user.Name:user.Company}</span>
                                  ))}</h5>{d.Subject}</div>
                                <div className="col-3 text-end" >
                                  {formatedatemail(d.SentDate)}
                                  <a className="bt-delete-message" onClick={e => handleDeleteMessage(e, d.id)}><FontAwesomeIcon icon={faTrash} /></a>
                                </div>
                                
                            </li>
                            ))}
                            {listevide}
                        </div>
                        <div className="col-md-8">
                                {mailSent.id?<div>
                                    <label>Destinataires :&nbsp;</label><em>{mailSent.Destinataires.map((d) => ((d.Company!=''?d.Company:d.FirstName+' '+d.Name)+"; "))}</em><br />
                                    <h5>{mailSent.Subject}</h5>
                                    {mailSent.pjs.length > 0?
                                    <div className="mb-2">
                                      {mailSent.pjs.map((d) =>(
                                        <div className="d-inline-block case-pj" key={d.id}  onClick={() =>  {handleClickPj(mailSent.id, d.Name)}}>
                                            <span className="h5" ><FontAwesomeIcon icon={faPaperclip} /></span>&nbsp;{d.Name}
                                        </div>
                                        ))}
                                    </div>
                                    :''}
                                    <div className="border p-2 mt-3"><div dangerouslySetInnerHTML={{__html :mailSent.Content}}></div></div>
                                </div>:<div className="text-center text-muted">Vous devez sélectionner un message</div>}
                        </div>
                        <div className="col-md-2">

                        </div>
                    </div>
                </TabPanel>
                {currentCommercial==0?
                <TabPanel>
                    <EditMail draft={draftMail} onSent={handleMessageSent}/>
                </TabPanel>
                :''}
            </Tabs>
            
          </div>
    );
    
  
}

export default Mailbox;
