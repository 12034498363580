import axios from "axios";
import { DCAuthToken } from "./Settings";
const token = DCAuthToken();
const XData = axios.create({
  baseURL: process.env.REACT_APP_USER_API_BASE_URL + "mail", 
  headers : { Authorization : 'Bearer ' + token }
});

export async function UnreadMails(SetDatas)
{
  var result;
  result = await XData.get('/unread');
  SetDatas(result.data.count);
}

export async function GetMails(idUser, SetDatas)
{
  var result;
  if(idUser == 0)
    result = await XData.get('/');
  else
    result = await XData.get('/user/'+idUser);
  SetDatas(result.data);
  
}


export async function GetMail(id, SetDatas)
{
    const result = await XData.get('/'+id);
    SetDatas(result.data);
  
}

export async function GetSentmails(idUser, SetDatas)
{
  var result;
  if(idUser == 0)
    result = await XData.get('/sent');
  else
    result = await XData.get('/sent?user='+idUser);
  SetDatas(result.data);
}

export async function SendMail(datas, SetDatas)
{
    const body = {
        Subject: datas.Subject, 
        Content: datas.Content, 
        destinataires: datas.destinataires,
        type_destinataire: datas.type_destinataire,
        pjs: datas.pjs
      };
    const result = await XData.post('/', body);
    SetDatas(result.data);
}

export async function GetReplyMail(id, SetDatas)
{
    const result = await XData.get('/reply/'+id);
    SetDatas(result.data);
}

export async function OpenPJ(idMail, nomPJ)
{
  window.open(process.env.REACT_APP_USER_API_BASE_URL + "mail/"+idMail+'/attachment/'+nomPJ+'/?token='+token);
}

export async function DeleteMessage(id, afterDelete)
{
  const result = await XData.delete('/'+id);
  afterDelete();
}