import { useState, useEffect } from "react";
import { ResetPassword, CheckKeyReset, ValidResetPassword } from "../functions/Users";
import { useParams, useNavigate  } from 'react-router-dom';

import logo from '../logo.png';

const ResetPass = (props) => { 
    const { key } = useParams();
    const [password, setPassword] = useState();
    const [confirm, setConfirm] = useState();
    const [email, setEmail] = useState();
    const [firstInit, setFirstInit] = useState(false);
    const [showInterface, setShowInterface] = useState(-1);

    const [messageSuccess, setMessageSuccess] = useState('');
    const [messageError, setMessageError] = useState('');
    var messages = '';

    const navigate = useNavigate();

    const handleValid = (event) => {
        event.preventDefault();
        setMessageSuccess('');
        setMessageError('');
        if(password == confirm)
        {
            ValidResetPassword(key, password, afterValidPassword);
        }
        else
        {
            setMessageError('Les mots de passe saisis sont différents !');
        }
        
    };

    const afterValidPassword = (ok) => {
        if(ok == 1)
        {
            setShowInterface(3);
            setMessageSuccess("Votre mot de passe a été modifié. Vous pouvez désormais vous connecter.")
        }
        else
        {
            setMessageError('Une erreur est survenue lors de la mise à jour de votre mot de passe. Merci de réessayer plus tard.');
        }
    }

    const handleReinit = () => {
        setShowInterface(2);
    };

    const handleValidReinit = (event) => {
        event.preventDefault();
        setMessageSuccess('');
        setMessageError('');
        ResetPassword(email, afterValidReinit);
    }

    const afterValidReinit = (ok) => {
        if(ok==1)
        {
            setMessageSuccess("Un email vous permettant de définir votre mot de passe a été envoyé à l'adresse email "+email);
        }
        else
            setMessageError("Aucun compte avec cet email n'a été trouvé. Merci de vérifier vos identifiants.")
    }
    const handleConnexion = () => {
        navigate("/");
    };

  const [isRedirect, setIsRedirect] = useState(false);
  
  if(firstInit == false)
  {
    if(key)
        CheckKeyReset(key, setShowInterface);
    else
        setShowInterface(2);
    setFirstInit(true);
  }
  
  useEffect(() => {
    //redirectToHome();
  }, [isRedirect]);
 
  if(messageError!="") messages+= '<div class="alert alert-danger">'+messageError+'</div>';
  if(messageSuccess!="") messages+= '<div class="alert alert-success">'+messageSuccess+'</div>';
  switch(showInterface)
  {
    case 1:
        return (
            <div id="identification">
              <div className="container text-center">
                <div className="row">
                  <div className="col-md-4 offset-md-4">
                    <img src={logo}/>
                    <h1 className="h3 my-3">Définition d'un nouveau mot de passe</h1>
                    <div dangerouslySetInnerHTML={{__html :messages}}></div>
                    <form action="submit" onSubmit={handleValid}>
                        <input type="password" className="form-control mb-2" name="password" onChange={e => { setPassword(e.target.value); }} placeholder="Mot de passe"/>
                        <input type="password" className="form-control mb-2" name="confirm" onChange={e => { setConfirm(e.target.value); }} placeholder="Confirmez votre mot de passe"/>
        
                        <button className="btn btn-primary" type="submit" >Valider</button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          );
        break;
    case 2:
            return (
                <div id="identification">
                  <div className="container text-center">
                    <div className="row">
                      <div className="col-md-4 offset-md-4">
                        <img src={logo}/>
                        {props.create?<h1 className="h3 my-3">Création de votre mot de passe</h1>:<h1 className="h3 my-3">Réinitialisation de mot de passe</h1>}
                        <div dangerouslySetInnerHTML={{__html :messages}}></div>
                        <form action="submit" onSubmit={handleValidReinit}>
                            <input type="email" className="form-control mb-2" name="email" onChange={e => { setEmail(e.target.value); }} placeholder="Votre email"/>
                            
                            <button className="btn btn-primary" type="submit" >Valider</button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              );
            break;
    case 3:
                return (
                    <div id="identification">
                      <div className="container text-center">
                        <div className="row">
                          <div className="col-md-4 offset-md-4">
                            <img src={logo}/>
                            <h1 className="h3 my-3">Mot de passe réinitialisé</h1>
                            <div className="alert alert-success">{messageSuccess}</div>
                            <button className="btn btn-primary my-3" onClick={handleConnexion} type="button" >Connexion</button>
                            
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                break;
    case 0:
        return (
            <div id="identification">
              <div className="container text-center">
                <div className="row">
                  <div className="col-md-6 offset-md-3">
                    <img src={logo}/>
                    <h1 className="h3 my-3">Définition d'un nouveau mot de passe</h1>
                    <div dangerouslySetInnerHTML={{__html :messages}}></div>
                    <div className="text-center">
                        <div className="text-danger">Le lien de réinitialisation de mot de passe utilisé n'est pas valide.</div>
                        <button className="btn btn-primary my-4" onClick={handleReinit} type="button">Réinitialiser mon mot de passe</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        break;
    default:
        return (
            <div id="identification">
              <div className="container text-center">
                <div className="row">
                  <div className="col-md-6 offset-md-3">
                    <img src={logo}/>
                    <h1 className="h3 my-3">Définition d'un nouveau mot de passe</h1>
                    <div dangerouslySetInnerHTML={{__html :messages}}></div>
                    <div className="text-center">
                        <em>Chargement en cours...</em>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        break;
  }
  
}



export function LoadScreen(page)
{
  //Navigate(page);
}

export default ResetPass;
