import { useState} from 'react';
import Promotions from "./Promotions";
import Actualites from "./Actualites";
import BottomToolbar from './ToolbarBottom'
import Catalogues from "./Catalogues";
import Services from "./Services";
import { CurrentServices, PathImageService, PathDocumentService} from '../functions/Services';

function Home(props) {
    const [firstInit, SetFirstInit] = useState(false);
    const [services, SetServices] = useState([]);
    
    const handleClickService = (id) => {
        window.open(PathDocumentService(id));
    }

    if(firstInit==false)
    {
        CurrentServices(SetServices);
        SetFirstInit(true);
    }

    return (
        <div id="home" className="main-content py-5">
            <div className="container-xxl mb-4">
                <div className="row">
                    <div className="col-md-4 mb-4 mb-md-0">
                        <Promotions screenHandler={props.screenHandler} modeHome="1"/>
                    </div>
                    <div className="col-md-4 mb-4 mb-md-0">
                        <Actualites screenHandler={props.screenHandler} modeHome="1"/>
                    </div>
                    <div className="col-md-4">
                        <div id="services" className="home-tile pt-5">
                            <h2 className="text-center text-uppercase color-primary">Côté services</h2>
                            {services.map((d) =>(
                            <div className="row mx-3 mb-2" onClick={() => {handleClickService(d.id)}}>
                                <div className="col-3 p-2 text-center"> <img className="icoService" src={PathImageService(d.id)}  /></div>
                                <div className="col-9 p-2 fw-bold"><table className="h-100"><tr><td className="align-middle h5">{d.Title}</td></tr></table></div>
                            </div>
                            
                            
                                ))}
                        </div>
                    </div>
                </div>
            </div>
            <BottomToolbar screenHandler={props.screenHandler}/>
        </div>
    );
}


export default Home;