import DCLoader from './DCLoader';
import { useState} from 'react';
import BottomToolbar from './ToolbarBottom'
import { GetAccount } from '../functions/Users';

function PageStatique(props) {
    const [firstInit, SetFirstInit] = useState(false);
    const [theRecord, SetTheRecord] = useState([]);

    function searchDatas()
    {
        GetAccount(SetTheRecord);
    }
    
    if(firstInit==false)
    {
        searchDatas();
        SetFirstInit(true);
    }
      
    
    switch(props.screen)
    {
        case 'pieces':
            return(
                <div id="pieces" className="pages-statiques main-content">
                
                
                <div className="container-xxl mb-5 contenu-central">
                    <div className="row">
                        <div className="col-12">
                            <h1 className="text-left my-2 my-md-5 text-uppercase titre-page">L'offre pièces la plus complète</h1>
                            <h2 className="color-primary text-uppercase">Pièces mécaniques, pièces carrosserie, outillage et équipement</h2>
                            <h2 className="text-uppercase">Les partsmen en ont pour tous les goûts</h2>
                            <div className="mt-5 text-center">
                                <img src="/img/illustration_pieces.png" className="mw-100" />
                            </div>
                        </div>
                    </div>
                </div>
                
                <BottomToolbar screenHandler={props.screenHandler}/>
            </div>
            );    
        
            break;
        case 'livraisons':
            
            return(
                <div id="livraisons" className="pages-statiques main-content">
                
                
                
                <div className="container-xxl mb-5 contenu-central">
                    <h1 className="text-left my-2 my-md-5 text-uppercase titre-page">Jusqu'à 4 livraisons par jour</h1>
                    <div className="row text-center ">
                        <div className="col-12 ">
                            <div className="bg-light pt-5 px-4">
                                <h2 className="font-weight-bold text-uppercase titre-magasin">{theRecord.Magasin.Nom}</h2>
                                <div className="row mt-4">
                                    <div className="col-md-3 px-4">
                                        <img src="/img/partsmen-champigny.jpg" className="w-100 border border-primary" />
                                        <h3 className="text-uppercase mt-3">Adresse</h3>
                                        <p>
                                        {theRecord.Magasin.Adresse}<br/>
                                        {theRecord.Magasin.CodePostal} {theRecord.Magasin.Ville}
                                        </p>
                                    </div>
                                    <div className="col-md-3 px-4 border-left border-primary">
                                        <div class="ico-circle"><table><tr><td><img src="/img/ico-horaires.png" /></td></tr></table></div>
                                        <h3 className="text-uppercase mt-3">Horaires d'ouverture</h3>
                                        <p>
                                        {theRecord.Magasin.Horaires}

                                        </p>
                                    </div>
                                    <div className="col-md-3 px-4 border-left border-primary">
                                        <div class="ico-circle"><table><tr><td><img src="/img/ico-cutoff.png" /></td></tr></table></div>
                                        <h3 className="text-uppercase mt-3">Cut off</h3>
                                        <p>
                                        {theRecord.Magasin.CutOff}
                                        </p>
                                    </div>
                                    <div className="col-md-3 px-4 border-left border-primary">
                                        <div class="ico-circle"></div>
                                        <h3 className="text-uppercase mt-3">Votre ATC</h3>
                                        <p>
                                        {theRecord.Commercial}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <BottomToolbar screenHandler={props.screenHandler}/>
            </div>
            );
            break;
        case 'callcenter':
            
            return(
                <div id="callcenter" className="pages-statiques main-content">
                <div className="container-xxl mb-5 contenu-central">
                    <h1 className="text-left my-2 my-md-5 text-uppercase titre-page">Call center centralisé</h1>
                    <div className="bg-primary pb-3 text-center ">
                        <div className="row">
                            <div className="col-md-4 pe-0">
                                <img src="/img/callcenter.jpg" className="w-100" />
                            </div>
                            <div className="col-md-4 px-4 bg-light">
                                <table className="h-100 w-100">
                                    <tr>
                                        <td className="align-middle">
                                            Nos 12 conseillers-comptoirs sont<br/>
                                            sur le pont  pour identifier et trouver<br/>
                                            dans les meilleurs délais<br/>
                                            la pièce qu’il vous faut.<br/>
                                            <br/>
                                            DU LUNDI AU SAMEDI<br/>
                                            de 8H30 à 12h30<br/>
                                            et de 13h30 à 18H30
                                        </td>
                                    </tr>
                                </table>
                            </div>
                            <div className="col-md-4 h2">
                                <table className="h-100 w-100">
                                    <tr>
                                        <td className="align-middle font-weight-bold">
                                            <a href="tel:+33184040666">01 84 04 06 66</a><br />
                                            <a href="mailto:callcenter@partsmen.fr">callcenter@partsmen.fr</a>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                
                <BottomToolbar screenHandler={props.screenHandler}/>
            </div>
            );
            break;
        case 'pros':
        default:
        return (
            <div id="pros" className="pages-statiques main-content">
                
                
                <div className="container-xxl mb-5 contenu-central">
                    <div className="row">
                        <div className="col-12">

                        
                            <h1 className="text-left my-2 my-md-5 text-uppercase titre-page">Pros à votre service</h1>
                            <h2 className="color-primary text-uppercase">Coté crédibilité, réactivité, proximité et disponibilité</h2>
                            <h2 className="text-uppercase">Les partsmen roulent pour vous</h2>
                            <div className="row mt-5">
                                <div className="col col-md-4 " >
                                    <div className="bg-light pb-2 me-3">
                                        <img className="w-100 border-bottom-primary" src="/img/pro-1.jpg"  />
                                        <p className="text-center px-5">
                                        L’expertise de nos vendeurs est  notre principal atout pour vous conseiller et vous proposer le produit le plus adapté.
                                        </p>
                                    </div>
                                </div>
                                <div className="col col-md-4 " >
                                    <div className="bg-light pb-2 mx-2">
                                        <img className="w-100 border-bottom-primary" src="/img/pro-2.jpg"  />
                                        <p className="text-center px-5">
                                        Nos conseillers sont sur le pont pour identifier et trouver la pièce qu’il vous faut, dans les meilleurs délais.
                                        </p>
                                    </div>
                                </div>
                                <div className="col col-md-4 " >
                                    <div className="bg-light pb-2 ms-3">
                                        <img className="w-100 border-bottom-primary" src="/img/pro-3.jpg"  />
                                        <p className="text-center px-5">
                                        Nos livreurs sillonnent les routes parisiennes pour vous assurer une livraison conforme et dans les temps.
                                        </p>
                                    </div>
                                </div>
                                
                            
                            </div>
                        </div>
                    </div>
                </div>
                
                <BottomToolbar  screenHandler={props.screenHandler}/>
            </div>
        );
    }
}


export default PageStatique;