import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from "@fortawesome/free-solid-svg-icons";

function MailDest(props) {
    const handleRemove = () => {
        props.onRemove(props.id);
    };

    return (
        <div className="mail-dest">
            <span>{props.label}</span>
            <div onClick={handleRemove} className="closeButton"><FontAwesomeIcon icon={faXmark} /></div>
        </div>
    );
}

export default MailDest;